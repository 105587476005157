import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'

import { CheckIcon, ChevronDown } from 'lucide-react'
import { Button } from 'src/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from 'src/components/ui/form'
import { cn } from 'src/lib/utils'

import { UseFormReturn } from 'react-hook-form'
import { CountryList } from './countryList'

function CountrySelect({ form }: {form: UseFormReturn<any>}) {

    return (
        <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
                <FormItem className="flex flex-col ">
                    <FormLabel className="font-stolzl-r  text-lg" >Country <span className='text-red-500'>*</span></FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn(
                                        " justify-between p-6 font-stolzl rounded-full ",
                                        !field.value && "text-muted-foreground"
                                    )}
                                >
                                    {field.value === '' ? "Country" : field.value}
                                    <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[300px] p-0">
                            <Command>
                                <CommandInput
                                    placeholder="search..."
                                    className="h-9"
                                />
                                <CommandEmpty>No framework found.</CommandEmpty>
                                <CommandGroup>
                                    <PopoverClose>
                                        <CommandList>
                                            {CountryList.map((item: any) => (
                                                <CommandItem
                                                    value={item.shortName}
                                                    key={item.shortName}
                                                    onSelect={() => {
                                                        form.setValue("country", item.shortName)
                                                        form.clearErrors('country')
                                                    }}
                                                > <p className='text-left w-full'>{item.shortName}</p>
                                                    <CheckIcon
                                                        className={cn(
                                                            "ml-auto h-4 w-4",
                                                            item.shortName === field.value
                                                                ? "opacity-100"
                                                                : "opacity-0"
                                                        )}
                                                    />
                                                </CommandItem>
                                            ))}
                                        </CommandList>
                                    </PopoverClose>
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

export default CountrySelect