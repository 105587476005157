import { cn } from "src/lib/utils";

function Div(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  return (
    <div
      onClick={props.onClick}
      className={cn(
        " p-4 rounded-none   bg-paper text-neutral-950  dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

export default Div;
