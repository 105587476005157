import { useEffect } from "react";
import Div from "src/components/typography/div";
import H4 from "src/components/typography/h4";
import { useClaimStore } from "src/store/claimStore";
import { ProjectClaims } from "./projectclaims";

function Index(props: any) {
  const {
    defaultMonthFilter,
    year,
    quarter,
    setQuarterYear
  } = useClaimStore();
 
  useEffect(() => {
    if(!quarter)
    defaultMonthFilter();
  }, []);


  return (
    <Div>
      <div className="flex justify-between pb-6">
        <H4>Reward Claims</H4>
        {/* <SelectQuarter setQuarterYear={setQuarterYear} year={year} quarter={quarter} /> */}
      </div>
      <ProjectClaims/>
    </Div>
  );
}

export default Index;
