import { cn } from "src/lib/utils";
import { CustomTypographyProps } from "../Types";


function Lead({ children, className }: CustomTypographyProps) {
    return (
        <p className={cn("text-xl text-muted-foreground", className)} >{children}</p>

    )
}

export default Lead