import { SearchIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useProjectStore } from "src/store/projectStore";
import { Input } from "../ui/input";

function SearchComponent() {
  const [title, setTitle] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);
  const { filterProjectsWithTitle } = useProjectStore();

  useEffect(() => {
    if (title === ("" || null)) return;
    filterProjectsWithTitle(title);
  }, [title]);
  return (
    <div className="relative ">
      <Input
        onChange={(e: any) => setTitle(e.target.value)}
        className="min-w-[150px] text-md rounded-full pr-10"
        placeholder="Search"
        ref={ref}
      />
      <SearchIcon className="text-gray-500 cursor-pointer absolute right-0  h-full top-0 mr-3  " />
    </div>
  );
}

export default SearchComponent;
