import Div from 'src/components/typography/div'
import H4 from 'src/components/typography/h4'
import { Card, CardDescription, CardFooter, CardTitle } from 'src/components/ui/card'

function SupportTab() {

    return (
        <Div className='mx-auto w-full text-center h-[90%] flex'>

            <Card className='flex-1'>
                <div className=' w-full h-full  flex flex-col  justify-center items-center'>
                    <img className='mx-auto sm:max-w-sm  py-12' src="/images/support.svg" alt="" />
                    <CardTitle className='text-3xl font-normal text-center'>{"We're here to help."}</CardTitle>
                    <CardDescription className='text-center  text-md max-w-3xl mx-auto py-2 px-12'>{"All questions are valid questions. For reward details, eligibility, if you need a hand or other inquiries, contact us and we will get back to you within 24 hours."}
                    </CardDescription>
                    < CardFooter className='pt-6 flex flex-col'>
                        <H4 className='text-center mx-auto font-normal' >Contact us to get support</H4>
                        <a className='text-primary' href="mailto:partners@cloudzenia.com">partners@cloudzenia.com</a>
                    </CardFooter>
                </div>            </Card>

        </Div>
    )
}

export default SupportTab