import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, USERTYPE } from "src/components/Types";
import Spinner from "src/components/ui/spinner";
import { removeLocalData } from "./Api";
import { useAuth } from "./useAuth";

function PrivateRoute(props: any) {
  const { user, setUser } = useAuth();

  useEffect(() => {
    if (!user) {
      const token = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      );
      const json = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
      );

      if (!token || !json) {
        nav("/login");
        return;
      }

      const userData = JSON.parse(json);
      if(!userData.userType) removeLocalData()
      setUser(userData);
    }
  }, []);
  
  const nav = useNavigate();
  useEffect(() => {
    
    if (!user) return;
    const isInvited = user?.userInvited;
    user?.userType === USERTYPE.SUPER_ADMIN && nav("/dashboard/admin/projects");
    user?.userType === USERTYPE.ADMIN && nav("/dashboard/onboarding");
    user?.userType === USERTYPE.USER && nav("/dashboard/projects");
    !isInvited && nav("/dashboard/waiting");

  }, [user]);

  if(!user) return <Spinner/>
  return <Outlet />;
}

export default PrivateRoute;
