import { Loader2 } from 'lucide-react'

function Spinner() {
    return (
        <div className=' flex justify-center content-center h-[50vh]'>
            <Loader2 className='animate-spin m-auto h-12 w-12 text-primary' />
        </div>
    )
}

export default Spinner