import { CalendarIcon } from "@radix-ui/react-icons";
import { PopoverClose } from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Textarea } from "src/components/ui/textarea";
import { cn } from "src/lib/utils";
import { convertTimestamp } from "src/store/projectStore";


function Status(props: any) {
  const { project, index } = props;
  const form = useForm({
    defaultValues: {
      arpStatus: project?.arpStatus,
      comments: project?.comments,
      rewardAmount: project?.rewardAmount,
      cashUnblocked: project?.cashUnblocked,
      approvedDate: project?.approvedDate,
      reviewedDate: project?.reviewedDate,
    },
    mode: "all",
  });

  form.watch('arpStatus')

  useEffect(()=>{
    form.reset(project)
  },[project])

  function handleUpdate(values: any) {
    const updatedProject = { ...project, ...values };
    return props.updateProject(updatedProject, index);
  }

  function handleARPUpdate(e: string) {
    if (e  === "approved") { 

        form.setValue("cashUnblocked", "Y");
        form.setValue('approvedDate',
          convertTimestamp(new Date().toDateString())) 
    } else if (e === 'rejected') { 
      form.setValue("cashUnblocked", "N")
      form.setValue("approvedDate", null)
    } 
    else if (e === 'review') { 
      form.setValue("cashUnblocked", "N")
      form.setValue("approvedDate", null)
    } 
    form.setValue('reviewedDate',
      convertTimestamp(new Date().toDateString())) 
    form.setValue("arpStatus", e);
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={`flex  justify-between hover:text-white text-white w-full ${
            project.arpStatus === "rejected"
              ? "bg-red-500 hover:bg-red-500"
              : project.arpStatus === "approved"
              ? "bg-green-500 hover:bg-green-500"
              : "bg-yellow-500 hover:bg-yellow-500"
          }`}
        >
          <span>{project.arpStatus}</span> <ChevronDown />{" "}
        </Button>
      </PopoverTrigger>
      <PopoverContent align={"end"} className="w-100 ">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleUpdate)}>
            <div className="grid gap-4">
              <div className="space-y-2">
                <div className="flex justify-between">
                  <div>
                    <h4 className="font-medium leading-none">Update Project</h4>
                    <p className="text-sm text-muted-foreground">
                      Set values for the project.
                    </p>
                  </div>
                  <PopoverClose>
                    <Button type="submit">submit</Button>
                  </PopoverClose>
                </div>
              </div>
              <div className="grid gap-2 grid-cols-2">
                <div className="grid grid-cols-3 col-span-2 items-center gap-4">
                  <FormField
                    control={form.control}
                    name="arpStatus"
                    render={({ field }) => (
                      <FormItem className=" col-span-3">
                        <FormLabel>Status</FormLabel>
                        <FormControl>
                          <Select
                            defaultValue={project.arpStatus}
                            onValueChange={handleARPUpdate}
                          >
                            <SelectTrigger
                              className={` text-white ${
                                form.getValues("arpStatus") === "rejected"
                                  ? "bg-red-500"
                                  : form.getValues("arpStatus") === "approved"
                                  ? "bg-green-500"
                                  : "bg-yellow-500"
                              }`}
                            >
                              <SelectValue />
                              <ChevronDown />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {["approved", "rejected", "review"].map(
                                  (item) => (
                                    <SelectItem value={item}>
                                      {"rejected" === item
                                        ? "Reject"
                                        : "approved" === item
                                        ? "Approve"
                                        : "Review"}
                                    </SelectItem>
                                  )
                                )}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-3 items-center gap-4">
                  <FormField
                    control={form.control}
                    name="cashUnblocked"
                    render={({ field }) => (
                      <FormItem className=" col-span-3">
                        <FormLabel>Cash Unblock</FormLabel>
                        <FormControl>
                          <Select
                            disabled={
                              form.getValues("arpStatus") !== "approved"
                            }
                            {...field}
                            onValueChange={field.onChange}
                          >
                            <SelectTrigger>
                              <SelectValue />
                              <ChevronDown />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value={"Y"}>Yes</SelectItem>
                                <SelectItem value={"N"}>No</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-3 items-center gap-4">
                  <FormField
                    control={form.control}
                    name="rewardAmount"
                    render={({ field }) => (
                      <FormItem className=" col-span-3">
                        <FormLabel>Amount ₹</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="000"
                            {...field}
                            onChange={(e) =>
                              form.setValue(
                                "rewardAmount",
                                +e.target.value.replace(
                                  new RegExp("[^0-9]"),
                                  ""
                                )
                              )
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid items-center gap-4">
                  <FormField
                    control={form.control}
                    name="reviewedDate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col ">
                        <FormLabel>Review Date</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-[240px] pl-3 text-left font-normal",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  new Date(field.value).toLocaleDateString()
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              fromDate={new Date()} 
                              onSelect={field.onChange}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid items-center gap-4">
                  <FormField
                    control={form.control}
                    name="approvedDate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col ">
                        <FormLabel>Approved Date</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-[240px] pl-3 text-left font-normal",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  new Date(field.value).toLocaleDateString()
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              mode="single"
                              fromDate={new Date()} 
                              selected={field.value}
                              onSelect={field.onChange}

                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid  col-span-2 items-center gap-4">
                  <FormField
                    control={form.control}
                    name="comments"
                    render={({ field }) => (
                      <FormItem className=" col-span-3">
                        <FormLabel>Comments</FormLabel>
                        <FormControl>
                          <Textarea
                            rows={3}
                            placeholder="comments..."
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>{" "}
          </form>
        </Form>
      </PopoverContent>
    </Popover>

    // <Select
    //   defaultValue={project.arpStatus}
    //   onValueChange={(e) =>
    //     table.options.meta?.updateStatus(row.index, column.id, e)
    //   }
    // >
    //   <SelectTrigger
    //     className={` text-white ${
    //       project.arpStatus === ProjectStatusEnum.REJECTED
    //         ? "bg-red-500"
    //         : project.arpStatus === ProjectStatusEnum.APPROVED
    //         ? "bg-green-500"
    //         : "bg-yellow-500"
    //     }`}
    //   >
    //     <SelectValue />
    //     <ChevronDown />
    //   </SelectTrigger>
    //   <SelectContent>
    //     <SelectGroup>
    //       {Object.values(ProjectStatusEnum).map((item) => (
    //         <SelectItem
    //           className={` text-white ${
    //             item === ProjectStatusEnum.REJECTED
    //               ? "bg-red-500"
    //               : item === ProjectStatusEnum.APPROVED
    //               ? "bg-green-500"
    //               : "bg-yellow-500"
    //           } `}
    //           value={item}
    //         >
    //           {ProjectStatusEnum.REJECTED === item
    //             ? "Reject"
    //             : ProjectStatusEnum.APPROVED === item
    //             ? "Approve"
    //             : "Review"}
    //         </SelectItem>
    //       ))}
    //     </SelectGroup>
    //   </SelectContent>
    // </Select>
  );
}

export default Status;
