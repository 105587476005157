export const AwsServicesList = [
  {
    label: " IAM (Identity and Access Management)",
    value: " IAM (Identity and Access Management)",
  },
  {
    label: " Shield",
    value: " Shield",
  },
  {
    label: " WAF (Web Application Firewall)",
    value: " WAF (Web Application Firewall)",
  },
  {
    label: "Amazon Cognito",
    value: "Amazon Cognito",
  },
  {
    label: " Key Management Service (KMS)",
    value: " Key Management Service (KMS)",
  },
  {
    label: " Secrets Manager",
    value: " Secrets Manager",
  },
  {
    label: " Certificate Manager",
    value: " Certificate Manager",
  },
  {
    label: " Directory Service",
    value: " Directory Service",
  },
  {
    label: " Firewall Manager",
    value: " Firewall Manager",
  },
  {
    label: " Artifact",
    value: " Artifact",
  },
  {
    label: "Amazon VPC (Virtual Private Cloud)",
    value: "Amazon VPC (Virtual Private Cloud)",
  },
  {
    label: " Direct Connect",
    value: " Direct Connect",
  },
  {
    label: "Amazon Route 53",
    value: "Amazon Route 53",
  },
  {
    label: " CloudFront",
    value: " CloudFront",
  },
  {
    label: " Global Accelerator",
    value: " Global Accelerator",
  },
  {
    label: "Amazon API Gateway",
    value: "Amazon API Gateway",
  },
  {
    label: " App Mesh",
    value: " App Mesh",
  },
  {
    label: " PrivateLink",
    value: " PrivateLink",
  },
  {
    label: " Transit Gateway",
    value: " Transit Gateway",
  },
  {
    label: " VPN",
    value: " VPN",
  },
  {
    label: " Migration Hub",
    value: " Migration Hub",
  },
  {
    label: " Application Discovery Service",
    value: " Application Discovery Service",
  },
  {
    label: " DataSync",
    value: " DataSync",
  },
  {
    label: " Transfer Family",
    value: " Transfer Family",
  },
  {
    label: " Snow Family",
    value: " Snow Family",
  },
  {
    label: " Database Migration Service",
    value: " Database Migration Service",
  },
  {
    label: " Server Migration Service",
    value: " Server Migration Service",
  },
  {
    label: " Migration Evaluator",
    value: " Migration Evaluator",
  },
  {
    label: " Application Migration Service",
    value: " Application Migration Service",
  },
  {
    label: " Data Transfer",
    value: " Data Transfer",
  },
  {
    label: "Amazon Managed Blockchain",
    value: "Amazon Managed Blockchain",
  },
  {
    label: "Amazon Quantum Ledger Database (QLDB)",
    value: "Amazon Quantum Ledger Database (QLDB)",
  },
  {
    label: "Amazon SageMaker",
    value: "Amazon SageMaker",
  },
  {
    label: "Amazon Comprehend",
    value: "Amazon Comprehend",
  },
  {
    label: "Amazon Polly",
    value: "Amazon Polly",
  },
  {
    label: "Amazon Rekognition",
    value: "Amazon Rekognition",
  },
  {
    label: "Amazon Lex",
    value: "Amazon Lex",
  },
  {
    label: "Amazon Forecast",
    value: "Amazon Forecast",
  },
  {
    label: "Amazon Personalize",
    value: "Amazon Personalize",
  },
  {
    label: "Amazon Textract",
    value: "Amazon Textract",
  },
  {
    label: " Deep Learning AMIs",
    value: " Deep Learning AMIs",
  },
  {
    label: " DeepComposer",
    value: " DeepComposer",
  },
  {
    label: "Amazon Athena",
    value: "Amazon Athena",
  },
  {
    label: "Amazon Kinesis",
    value: "Amazon Kinesis",
  },
  {
    label: "Amazon EMR (Elastic MapReduce)",
    value: "Amazon EMR (Elastic MapReduce)",
  },
  {
    label: "Amazon QuickSight",
    value: "Amazon QuickSight",
  },
  {
    label: " Glue",
    value: " Glue",
  },
  {
    label: " Data Pipeline",
    value: " Data Pipeline",
  },
  {
    label: " Lake Formation",
    value: " Lake Formation",
  },
  {
    label: " Data Exchange",
    value: " Data Exchange",
  },
  {
    label: " DMS (Database Migration Service)",
    value: " DMS (Database Migration Service)",
  },
  {
    label: " DataSync",
    value: " DataSync",
  },
  {
    label: " CloudWatch",
    value: " CloudWatch",
  },
  {
    label: " CloudTrail",
    value: " CloudTrail",
  },
  {
    label: " Config",
    value: " Config",
  },
  {
    label: "Amazon CloudWatch Logs",
    value: "Amazon CloudWatch Logs",
  },
  {
    label: " Systems Manager",
    value: " Systems Manager",
  },
  {
    label: " OpsWorks",
    value: " OpsWorks",
  },
  {
    label: " Service Catalog",
    value: " Service Catalog",
  },
  {
    label: " Trusted Advisor",
    value: " Trusted Advisor",
  },
  {
    label: " Control Tower",
    value: " Control Tower",
  },
  {
    label: " AppConfig",
    value: " AppConfig",
  },
  {
    label: " CodePipeline",
    value: " CodePipeline",
  },
  {
    label: " CodeBuild",
    value: " CodeBuild",
  },
  {
    label: " CodeDeploy",
    value: " CodeDeploy",
  },
  {
    label: " CodeCommit",
    value: " CodeCommit",
  },
  {
    label: " CodeStar",
    value: " CodeStar",
  },
  {
    label: " CodeArtifact",
    value: " CodeArtifact",
  },
  {
    label: " CodeGuru",
    value: " CodeGuru",
  },
  {
    label: " CodePipeline",
    value: " CodePipeline",
  },
  {
    label: " Cloud9",
    value: " Cloud9",
  },
  {
    label: " X-Ray",
    value: " X-Ray",
  },
  {
    label: "Amazon S3 (Simple Storage Service)",
    value: "Amazon S3 (Simple Storage Service)",
  },
  {
    label: "Amazon EBS (Elastic Block Store)",
    value: "Amazon EBS (Elastic Block Store)",
  },
  {
    label: "Amazon EFS (Elastic File System)",
    value: "Amazon EFS (Elastic File System)",
  },
  {
    label: " Storage Gateway",
    value: " Storage Gateway",
  },
  {
    label: "Amazon Glacier",
    value: "Amazon Glacier",
  },
  {
    label: " Backup",
    value: " Backup",
  },
  {
    label: "Amazon FSx",
    value: "Amazon FSx",
  },
  {
    label: " Snow Family",
    value: " Snow Family",
  },
  {
    label: " DataSync",
    value: " DataSync",
  },
  {
    label: " Transfer Family",
    value: " Transfer Family",
  },
  {
    label: "Amazon RDS (Relational Database Service)",
    value: "Amazon RDS (Relational Database Service)",
  },
  {
    label: "Amazon DynamoDB",
    value: "Amazon DynamoDB",
  },
  {
    label: "Amazon Redshift",
    value: "Amazon Redshift",
  },
  {
    label: "Amazon Neptune",
    value: "Amazon Neptune",
  },
  {
    label: "Amazon ElastiCache",
    value: "Amazon ElastiCache",
  },
  {
    label: "Amazon DocumentDB",
    value: "Amazon DocumentDB",
  },
  {
    label: "Amazon Keyspaces (for Apache Cassandra)",
    value: "Amazon Keyspaces (for Apache Cassandra)",
  },
  {
    label: "Amazon Quantum Ledger Database (QLDB)",
    value: "Amazon Quantum Ledger Database (QLDB)",
  },
  {
    label: " Database Migration Service",
    value: " Database Migration Service",
  },
  {
    label: " Glue",
    value: " Glue",
  },
  {
    label: " IoT Core",
    value: " IoT Core",
  },
  {
    label: " IoT Greengrass",
    value: " IoT Greengrass",
  },
  {
    label: " IoT Analytics",
    value: " IoT Analytics",
  },
  {
    label: "Amazon FreeRTOS",
    value: "Amazon FreeRTOS",
  },
  {
    label: " IoT Device Defender",
    value: " IoT Device Defender",
  },
  {
    label: " IoT Device Management",
    value: " IoT Device Management",
  },
  {
    label: " IoT Events",
    value: " IoT Events",
  },
  {
    label: " IoT SiteWise",
    value: " IoT SiteWise",
  },
  {
    label: " IoT Things Graph",
    value: " IoT Things Graph",
  },
  {
    label: " IoT TwinMaker",
    value: " IoT TwinMaker",
  },
  {
    label: "Amazon EC2 (Elastic Compute Cloud)",
    value: "Amazon EC2 (Elastic Compute Cloud)",
  },
  {
    label: "Amazon ECS (Elastic Container Service)",
    value: "Amazon ECS (Elastic Container Service)",
  },
  {
    label: " Lambda",
    value: " Lambda",
  },
  {
    label: " Elastic Beanstalk",
    value: " Elastic Beanstalk",
  },
  {
    label: " Fargate",
    value: " Fargate",
  },
  {
    label: "Amazon EC2 Auto Scaling",
    value: "Amazon EC2 Auto Scaling",
  },
  {
    label: " Batch",
    value: " Batch",
  },
  {
    label: " Outposts",
    value: " Outposts",
  },
  {
    label: " Wavelength",
    value: " Wavelength",
  },
  {
    label: "Amazon GameLift",
    value: "Amazon GameLift",
  },
  {
    label: "Amazon Lumberyard",
    value: "Amazon Lumberyard",
  },
];
