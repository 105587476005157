import { ReactNode } from 'react'
import Lead from 'src/components/typography/lead'
import { Separator } from 'src/components/ui/separator'
import { AvatarDropDown, MobileHeader, getUserName } from 'src/views/header'
interface Layoutprops {
    user: any
    device?: string
    children: ReactNode
}

function DashHeader({ device, user }: any) {
    return <div className=' flex  mt-3 justify-between'>
        <Lead
            className=' text-xl md:text-2xl w-max p-3 px-6  flex gap-2 '>
            Welcome back
            <p
                className='text-primary  font-semibold '>
                { user &&  getUserName(user)}!
            </p>
        </Lead >

        {device !== 'mobile' && <div className=' mr-12 my-auto'>
            <AvatarDropDown  user={user} />
        </div>}
    </div>
}

function Layout({ children, device, user }: Layoutprops) {
    return (
        <div className='bg-paper flex flex-col flex-1 '>
            {device === 'mobile' && 
            <MobileHeader user={user} />}
           
            <DashHeader device={device} user={user} />
            <div className='p-6 pt-4'>
                <Separator className='' />
            </div>
            {children}
        </div>
    )
}

export default Layout