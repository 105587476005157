import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronDown, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/ui/button";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/components/ui/select";
import Spinner from "src/components/ui/spinner";
import { useAuth } from "src/lib/useAuth";
import { useProjectStore } from "src/store/projectStore";
import { z } from "zod";
import { Capitalise } from "./viewprofile";

const banksList = [
  { label: "State Bank of India (SBI)", value: "state_bank_of_india_(sbi)" },
  { label: "Punjab National Bank (PNB)", value: "punjab_national_bank_(pnb)" },
  { label: "Bank of Baroda (BOB)", value: "bank_of_baroda_(bob)" },
  { label: "Canara Bank", value: "canara_bank" },
  { label: "Union Bank of India", value: "union_bank_of_india" },
  { label: "HDFC Bank", value: "hdfc_bank" },
  { label: "ICICI Bank", value: "icici_bank" },
  { label: "Axis Bank", value: "axis_bank" },
  { label: "Kotak Mahindra Bank", value: "kotak_mahindra_bank" },
  { label: "IndusInd Bank", value: "indusind_bank" },
  { label: "Citibank", value: "citibank" },
  { label: "Standard Chartered Bank", value: "standard_chartered_bank" },
  { label: "HSBC Bank", value: "hsbc_bank" },
  { label: "Deutsche Bank", value: "deutsche_bank" },
  { label: "Barclays Bank", value: "barclays_bank" },
  { label: "Punjab Gramin Bank", value: "punjab_gramin_bank" },
  {
    label: "Baroda Rajasthan Kshetriya Gramin Bank",
    value: "baroda_rajasthan_kshetriya_gramin_bank",
  },
  { label: "Kerala Gramin Bank", value: "kerala_gramin_bank" },
  {
    label: "Andhra Pradesh Grameena Vikas Bank",
    value: "andhra_pradesh_grameena_vikas_bank",
  },
  { label: "Uttar Bihar Gramin Bank", value: "uttar_bihar_gramin_bank" },
  { label: "Saraswat Co-operative Bank", value: "saraswat_co-operative_bank" },
  { label: "Apna Sahakari Bank", value: "apna_sahakari_bank" },
  {
    label: "The Bharat Co-operative Bank",
    value: "the_bharat_co-operative_bank",
  },
  {
    label: "Abhyudaya Co-operative Bank",
    value: "abhyudaya_co-operative_bank",
  },
  {
    label: "Rajkot Nagarik Sahakari Bank",
    value: "rajkot_nagarik_sahakari_bank",
  },
  { label: "Other", value: "other" },
];

const bankingDetailSchema = z
  .object({
    accountType: z.string().min(4, "Enter Account type"),
    bankName: z.string().min(4, "Enter Bank Name"),
    accountNumber: z.string().min(6, "Enter Account Number Properly"),
    accountNumberCheck: z.string(),
    ifscCode: z
      .string()
      .min(6, "Enter IFSC Code properly ")
      .max(11, "IFSC Code must be 11 Digits"),
  })
  .refine(
    (values) => {
      return values.accountNumber === values.accountNumberCheck;
    },
    {
      message: "Account Number must match!",
      path: ["accountNumberCheck"],
    }
  );

function AddBankingDetails() {
  const { postBankDetails, bankDetails, getBankDetails } = useProjectStore();
  const { user } = useAuth();
  const [edit, setEdit] = useState<boolean>(true);

  useEffect(() => {
    if (user) getBankDetails(user?.emailId);
  }, []);

  useEffect(() => {
    if (!user) return;
    if (bankDetails) {
      form.reset(bankDetails);
    }
    return;
  }, [bankDetails]);

  const form = useForm<z.infer<typeof bankingDetailSchema>>({
    resolver: zodResolver(bankingDetailSchema),
    defaultValues: {
      bankName: bankDetails?.bankName,
      accountType: bankDetails?.accountType,
      accountNumber: bankDetails?.accountNumber,
      accountNumberCheck: "",
      ifscCode: bankDetails?.ifscCode,
    },
    mode: "all",
  });

  if (!user) return <Spinner />;

  function onSubmit(values: any) {
    if (!values.emailId) {
      values.emailId = user?.emailId;
    }
    if (!values.fullname) {
      values.fullname =
        Capitalise(`${user?.firstName}`) +
        " " +
        Capitalise(`${user?.lastName}`);
    }
    postBankDetails(values);
    setEdit(true);
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-xl">Banking Details</CardTitle>
        <Button
          onClick={() => setEdit(!edit)}
          variant={edit ? "outline" : "secondary"}
        >
          <Pencil />
        </Button>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            className="space-y-2 px-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-2 gap-6"></div>
            <FormField
              control={form.control}
              name="accountType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Account Type</FormLabel>
                  <Select
                    disabled={edit}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Account types" />{" "}
                        <ChevronDown />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="savings_account">
                        Savings Account
                      </SelectItem>
                      <SelectItem value="current_account">
                        Current Account
                      </SelectItem>
                      <SelectItem value="fixed_deposit_account">
                        Fixed Deposit Account
                      </SelectItem>
                      <SelectItem value="recurring_deposit_account">
                        Recurring Deposit Account
                      </SelectItem>
                      <SelectItem value="public_provident_fund_account">
                        Public Provident Fund (PPF) Account
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="bankName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Bank Name</FormLabel>
                    <Select
                      disabled={edit}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <Input
                          disabled={edit}
                          {...field}
                          onChange={(e) =>
                            form.setValue(
                              "bankName",
                              e.target.value.replace(
                                new RegExp("[^a-zA-z|^s ]"),
                                ""
                              )
                            )
                          }
                        />
                      </FormControl>
                      <SelectContent>
                        {banksList.map((item) => (
                          <SelectItem value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="ifscCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>IFSC Code</FormLabel>
                    <FormControl>
                      <Input
                        disabled={edit}
                        placeholder=""
                        {...field}
                        onChange={(e) =>
                          form.setValue(
                            "ifscCode",
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </FormControl>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="accountNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Account Number</FormLabel>
                  <FormControl>
                    <Input
                      disabled={edit}
                      placeholder=""
                      {...field}
                      onPaste={(e) => {
                        e.preventDefault();
                        form.setError("accountNumber", {
                          message: "Please avoid pasting account number",
                        });
                      }}
                      onChange={(e) =>
                        form.setValue(
                          "accountNumber",
                          e.target.value.replace(new RegExp("[^0-9]"), "")
                        )
                      }
                    />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {!edit && (
              <>
                {" "}
                <FormField
                  control={form.control}
                  name="accountNumberCheck"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Refill Account Number</FormLabel>
                      <FormControl>
                        <Input
                          disabled={edit}
                          placeholder=""
                          type="password"
                          {...field}
                          onPaste={(e) => {
                            e.preventDefault();
                            form.setError("accountNumber", {
                              message: "Please avoid pasting account number",
                            });
                          }}
                          onChange={(e) =>
                            form.setValue(
                              "accountNumberCheck",
                              e.target.value.replace(new RegExp("[^0-9]"), "")
                            )
                          }
                        />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit">Submit</Button>
              </>
            )}
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

export default AddBankingDetails;
