import { Check, CheckCircle2 } from "lucide-react";
import { ButtonComp, DetailCardContent } from "./HeroPage";
import { Label } from "src/components/ui/label";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/ui/accordion";

const DetailCard = ({ content }: { content: DetailCardContent }) => {
  return (
    <div>
      <img className="pt-6 " src={content.img} alt="" />

      <h4 className="py-2 text-xl text-white pt-6">{content.label}</h4>
      <p className="font-stolzl text-gray-400">{content.text}</p>
    </div>
  );
};

const content: DetailCardContent[] = [
  {
    img: "/images/Rectangle 25.png",
    label: "Customer Type",
    text: `The program welcomes a spectrum of customers, particularly those in the early stages of AWS adoption, ranging from Small and Midsize Businesses, Startups, Digital Native Businesses, and Independent Software Vendors, to large-scale Enterprises.`,
  },
  {
    img: "/images/eli2.png",
    label: "Project Type",
    text: `To qualify, projects must launch on AWS Cloud with an anticipated annual service spend more than $5,000, reflecting a commitment to AWS's cloud offerings. This ensures a wide array of projects, from moderate to large-scale, can leverage the program's benefits.`,
  },
];

function CritieriaPage() {
  return (
    <div className="bg-[#1B1E2FCC] py-6 pb-16 ">
      <div className="max-w-7xl space-y-6 mx-auto mt-20">
        <h2 className="text-4xl font-stolzl-r text-white">
          {" "}
          Eligibility Criteria
        </h2>
        <p className="font-stolzl text-gray-400  leading-7">
          Participation in this program is open to IT & ITES Companies, ISVs,
          Individual freelancers, provided that their customers <br /> and
          projects align with the established eligibility requirements of this
          initiative.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-12 mx-auto max-w-7xl">
        {content.map((item) => (
          <DetailCard content={item} />
        ))}
      </div>
    </div>
  );
}

export default CritieriaPage;

const Tick = () => (
  <span className="relative ">
    {" "}
    <CheckCircle2 className="bg-cPink relative ring-offset-2 ring-2 rounded-full  fill-cPink  text-cPink   ring-cPink"></CheckCircle2>
    <Check className="absolute text-white top-0" />
  </span>
);
export const HowItWorks = () => {
  return (
    <div className="max-w-7xl mx-auto">
      <div className="max-w-7xl space-y-6 mx-auto mt-20">
        <h2 className="text-4xl font-stolzl-r text-center"> How it works?</h2>
        <p className="font-stolzl text-gray-400 text-center leading-7">
          Elevate your business by joining CloudZenia's Reward Program and
          receive end-to-end support towards building successful <br />{" "}
          softwares on AWS.
        </p>
      </div>

      <div className="min-h-[80vh] flex flex-col justify-center sm:py-12">
        <div className=" sm:max-w-6xl sm:mx-auto w-full px-2 sm:px-0">
          <div className="relative text-gray-700 antialiased text-sm font-semibold">
            <div className="hidden sm:block w-[2px] h-[78%] absolute left-1/2 transform -translate-x-1/2 bg-cPink"></div>
            <div className="mt-6 sm:mt-0">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-end w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div className=" font-stolzl  space-y-2">
                      <Label className=" font-stolzl-r text-lg">
                        Enrol as a Partner
                      </Label>
                      <p>
                        Begin a rewarding partnership by enrolling as our
                        Partner. Forge a connection with your dedicated Partner
                        Manager at Cloudzenia, who will guide you through a
                        comprehensive registration process. Our streamlined
                        protocol ensures you're well-positioned to leverage our
                        resources and expertise from day one.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full  border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-14 transform -translate-x-1/2 flex items-center justify-center">
                  <Tick />
                </div>
              </div>
            </div>
            <div className=" "> </div>
            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8 text-right">
                    <div className=" font-stolzl  space-y-2">
                      <Label className=" font-stolzl-r text-lg">
                        Identify and Initiate Opportunities
                      </Label>
                      <p>
                        Discover the potential within your portfolio and select
                        projects ripe for deployment on AWS. Submit your
                        carefully crafted deals for our review, and anticipate
                        the eligibility confirmation to arrive within 30 days.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-8  transform -translate-x-1/2 flex items-center justify-center">
                  <Tick />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-end w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div className=" font-stolzl  space-y-2">
                      <Label className=" font-stolzl-r text-lg">
                        Comprehensive End-to-End Support
                      </Label>
                      <p>
                        Benefit from the dedicated assistance of our AWS
                        certified Solution Architects, who offer you unwavering
                        end-to-end support. Their expertise is at your disposal,
                        helping you navigate the complexities of deploying your
                        customer projects on AWS with finesse and strategic
                        insight.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-12  transform -translate-x-1/2 flex items-center justify-center">
                  <Tick />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8 text-right">
                    <div className=" font-stolzl  space-y-2">
                      <Label className=" font-stolzl-r text-lg">
                        Earn Valuable Rewards
                      </Label>
                      <p>
                        As a testament to your successful project launches on
                        AWS, receive substantial cash incentives as a part of
                        our rewards program. Following the approval of your
                        projects, expect prompt and assured incentives within 45
                        days of quarter closing, a token of our appreciation for
                        your commitment and excellence.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-14  transform -translate-x-1/2 flex items-center justify-center">
                  <Tick />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <ButtonComp>Join our Network</ButtonComp>
      </div>
    </div>
  );
};

const faqs = [
  {
    question:
      "Is the provision of detailed credentials necessary for participation?",
    answer: "",
  },
  {
    question: "Does the program facilitate customer management on our behalf?",
    answer: "",
  },
  { question: "How are cash incentives claimed and paid out?", answer: "" },
  {
    question:
      "Is there a limit to how many projects can be submitted each quarter?",
    answer: "",
  },
];

const FaqItem = ({ content }: any) => (
  <AccordionItem value={content.question}>
    <AccordionTrigger>{content.question}</AccordionTrigger>
    <AccordionContent>{content.answer}</AccordionContent>
  </AccordionItem>
);

export const FAQ = () => {
  return (
    <div className="bg-[#F8F8F8] space-y-4">
      <div className="max-w-7xl space-y-6 mx-auto mt-20">
        <h2 className="text-4xl font-stolzl-r text-center">
          {" "}
          Frequently Asked questions
        </h2>
        <p className="font-stolzl text-gray-400 text-center leading-7">
          Delve into the CloudZenia's Reward Program with these expertly curated
          FAQs, designed to give you a complete overview and <br />
          prepare you for a successful partnership.
        </p>
      </div>
      <div className="max-w-4xl mx-auto">
        <Accordion type="single" collapsible className="w-full">
          {faqs.map((item) => (
            <FaqItem content={item} />
          ))}
        </Accordion>
      </div>
    </div>
  );
};
