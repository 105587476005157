import {
  BankingDetailsDto,
  BankingDetailsReponseDto,
  CreateRewardClaimDto,
  GetAll,
  InoviceDto,
  InoviceResponseDto,
  ProjectDto,
  ProjectResponseDto,
  QYFilters,
  UpdateProjectStatus,
  claimUpdateDto,
  createUserDto,
} from "src/components/Types";
import { apiDelete, apiGet, apiPost, apiPostImage, apiPut } from "./Api";

export const routes = {
  APIS: {
    AUTHENTICATION: {
      SIGN_UP: async (data: any) =>
        await apiPost("/authentication/signup", data),
      LOG_IN: async (data: any) => await apiPost("/authentication/login", data),
      LOG_OUT: async (data: any) =>
        await apiPost("/authentication/logout", data),
      SEND_OTP: async (data: any) =>
        await apiPost("/authentication/send-otp", data),
      VERIFY_OTP: async (data: any) =>
        await apiPost("/authentication/verify-otp", data),
    },
    USER: {
      UPDATE_USER_DETAILS: async (data: createUserDto) =>
        await apiPut("/user", data),
    },
    UPLOAD_IMAGE: {
      UPLOAD: async (data: any) => await apiPostImage("/file/upload", data),
      DELELE: async (document: string) => await apiDelete(`/file/${document}`),
    },
    PROJECTS: {
      CREATE: async (data: ProjectDto): Promise<ProjectResponseDto> =>
        await apiPost("/project/create", data),
      UPDATE: async (data: ProjectDto): Promise<ProjectResponseDto | any> =>
        await apiPut("/project/update", data),
      GET_ALL: async (emailId: string): Promise<GetAll> =>
        await apiGet(`/project/getAll/${emailId}`),
      DELETE: async (projectKey: string): Promise<ProjectResponseDto | any> =>
        await apiDelete(`/project/delete/${projectKey}`),
      GET_BY_Id: async (projectKey: string): Promise<ProjectResponseDto> =>
        await apiGet(`/project/get/${projectKey}`),
      UPLOAD_INVOICE: async (data: InoviceDto): Promise<InoviceResponseDto> =>
        await apiPost("/project/upload-invoice", data),
      GET_BY_Q_AND_Y: async (data: QYFilters) =>
        await apiGet(
          `/project/getAllByQuarter/${data.emailId}/${data.projectYear}/${data.quarter}`
        ),
    },
    EMAIL_CONTROLLER: {
      SEND_OTP_EMAIL: async (data: {
        email: string;
      }): Promise<{ status: boolean }> =>
        await apiPost("/email/send-otp-email", data),
      SEND_ONBOARDING_EMAIL: async (data: {
        emailId: string;
      }): Promise<{ status: boolean }> =>
        await apiPost("/email/invitation", data),
    },
    BANKING_DETAIL_CONTROLLER: {
      CREATE: async (
        data: BankingDetailsDto
      ): Promise<BankingDetailsReponseDto> =>
        await apiPost("/banking/create", data),
      GET_BY_ID: async (emailId: string): Promise<BankingDetailsReponseDto> =>
        await apiGet(`/banking/get/${emailId}`),
    },
    REWARD_CLAIMS: {
      CREATE: async (data: CreateRewardClaimDto) =>
        await apiPost("/admin/reward-claim/create", data),
      UPDATE_PROJECT_STATUS: async (data: UpdateProjectStatus) =>
        await apiPut("/admin/update-project-status", data),
      IS_REWARD_CLAIM_ELIGIBLE: async (emailId: string) =>
        await apiGet(`/admin/validate/is-create-claim-eligible/${emailId}`),
      GET_REWARD_CLAIMLIST: async (emailId: string) =>
        await apiGet(`/admin/reward-claim/list/${emailId}`),
      GET_REWARD_CLAIM_BY_KEY: async (rewardKey: string) =>
        await apiGet(`/admin/reward-claim/get/${rewardKey}`),
      CLAIM_UPDATE: async (data: claimUpdateDto) => await apiPut('/admin/reward-claim-update', data)
    }, 
    ADMIN: {
      GET_ALL_REWARD_CLAIMS: async (data: any) => await apiGet(`/admin/reward-claim/getAll?page=${data.page}&size=${data.size}`),
      UPDATE_PROJECT_DETAILS: async (data: UpdateProjectStatus) =>
        await apiPut("/admin/update-project-status", data),
      GET_ALL_PROJECTS: async  (data:{ page: number, size:number, projectYear: number, quarter: string, arpStatus: string, emailId: string}) =>  {
        
        const {page, size, projectYear, quarter, arpStatus, emailId } = data
        let q = '/project/getAll?'
        page ? q += `page=${page}` : ''
        projectYear ? q += `&year=${projectYear}` : ''
        size ? q += `&size=${size}` : ''
        quarter ? q += `&quarter=${quarter}` : ''
        emailId ? q += `&emailId=${emailId}` : ''
        arpStatus && arpStatus === 'all' ? '' :  q += `&arpStatus=${arpStatus}`

        return await apiGet(q)
      }},
  },
};
