import { ChevronRight, SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { ProjectResponseDto } from "src/components/Types";
import SearchComponent from "src/components/custom/SearchComponent";
import CustomDropdown from "src/components/custom/dropdown";
import Div from "src/components/typography/div";
import P from "src/components/typography/p";
import { Button } from "src/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import Spinner from "src/components/ui/spinner";
import { useAuth } from "src/lib/useAuth";
import { useProjectStore } from "src/store/projectStore";

import UserProjects from "../my-projects/UserProjects";
import RequestDetilsTab from "./reward-claim/request-details";

function CreateRewardClaim({ emailId, quarter, totalAmount, setShow }: any) {

  const { submitRewardRequest } = useProjectStore()

  function handleSubmit() {
    const data = { emailId, quarter, totalAmount, projectKey: 0, comments: "" };
    submitRewardRequest(data).then((res) => {
      setShow(false);
    });
  }

  return (
    <Div className="px-0 space-y-4 ">
      <Card className="mx-auto ">
        <CardHeader>
          <CardTitle className="text-xl">Create Reward Claim</CardTitle>
          <CardDescription>
            After you submit the reward claims, the rewards should be
            transferred in 3-5 working days.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                disabled
                value={emailId}
                id="email"
                type="email"
                placeholder="m@example.com"
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first-name">Reward Amount</Label>
                <Input
                  disabled
                  id="first-name"
                  placeholder="Max"
                  value={totalAmount}
                  required
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last-name">Quarter</Label>
                <Input
                  id="last-name"
                  disabled
                  placeholder="Robinson"
                  value={quarter}
                  required
                />
              </div>
            </div>

            <Button type="submit" className="w-full" onClick={handleSubmit}>
              Create Reward claim
            </Button>
          </div>
        </CardContent>
      </Card>
    </Div>
  );
}

async function createRC(
  isRewardClaimEligible: any,
  emailId: any,
  setShow: any
) {
  if (await isRewardClaimEligible(emailId)) {
    setShow(true);
    return;
  }
  toast.error("Looks like you dont have any reward claims for this quarter!!!");
  setShow(false);
  return;
}

export const SearchInput = ({ handleSearch, ref }: any) => {
  return (
    <Div className="flex gap-2">
      <div className="relative ">
        <CardDescription>
          <SearchIcon className=" absolute  h-full w-5 mx-2" />
        </CardDescription>
        <Input
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          className="px-10"
        />
      </div>{" "}
    </Div>
  );
};

function Claims({

  handleSearch,
  claimList,
  emailId,
  quarter,
  totalAmount,
}: any) {
  const { isCreateClaimEligible } = useProjectStore();
  const [show, setShow] = useState(false);
  return (
    <Div className="px-0 space-y-4">

        <div className="w-full flex justify-between">
          <Button
            onClick={() => createRC(isCreateClaimEligible, emailId, setShow)}
          >
            Create Reward Claim
          </Button>
        </div>

      <div className="flex justify-between ">
        <div className="flex justify-between w-full">
          <CardTitle className="text-lg font-normal">Previous Claims</CardTitle>
          <SearchComponent />
        </div>
      </div>
      {show ? (
        <CreateRewardClaim
          emailId={emailId}
          quarter={quarter}
          totalAmount={totalAmount}
          setShow={setShow}
        />
      ) : (
        <ClaimAccordions claims={claimList} handleSearch={handleSearch} />
      )}
    </Div>
  );
}
function SelectOptions({ quarters }: any) {
  return (
    <>
      {quarters.map((item: any) => (
        <SelectItem key={item.value} value={item.value}>
          {item.value}
        </SelectItem>
      ))}
    </>
  );
}
function SelectApprovalQuarter() {
  const { year, quarter, getProjectsWithFilters, setQuarterYear } =
    useProjectStore();
  const { user } = useAuth();

  const quarters = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ];
  const years = [
    { value: "2024", label: 2024 },
    { value: "2025", label: 2025 },
  ];
  useEffect(() => {
    if (!quarter && !year) return;

    getProjectsWithFilters({
      //@ts-ignore
      emailId: user.emailId,quarter,projectYear: year,
    });
  }, [quarter, year]);
  if (!quarter || !year) return <div> Something bad </div>;
  return (
    <div className="flex w-max ">
      <Select
        defaultValue={quarter ?? "Q1"}
        onValueChange={(e) => setQuarterYear(e, year)}
      >
        <SelectTrigger className=" focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue defaultValue={quarter} placeholder="Quarter" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={quarters} />
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        defaultValue={"2024"}
        onValueChange={(e) => setQuarterYear(quarter, e)}
      >
        <SelectTrigger className=" focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue placeholder="Year" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={years} />
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
function ClaimAccordions({ claims }: any) {
  const [_, setSearchParams] = useSearchParams();

  if (claims?.length <= 0)
    return (
      <Div>
        <Card className="shadow-none border-none">
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>

          <CardContent className="space-y-4 ">
            <img src="/images/add.png" className="mx-auto" alt="" />

            <h2 className="text-3xl text-center">
              Looks like you have no projects approved for Rewards yet
            </h2>

            <CardDescription className="text-center leading-5">
              Once You submit your projects wait <br /> atleast 3-5 business
              days for Reward approval
            </CardDescription>
          </CardContent>
          <CardFooter />
        </Card>
      </Div>
    );
  return (
    <>
      {claims?.map((item: any) => (
        <Card
          onClick={() => {
            setSearchParams({ requestId: item.rewardKey });
          }}
          className="shadow-none border-none hover:bg-gray-200 p-4 "
        >
          <CardContent className="py-2  rounded-md  px-0">
            <CardDescription className="flex justify-between px-2">
              <div className="flex flex-col">
                <div className=" font-bold  text-primary text-lg ">
                  {"₹ "}
                  {item?.totalAmount?.toLocaleString() ?? "00000"}
                </div>

                <div>
                  <h4 className="text-lg text-gray-500">{item.emailId}</h4>
                </div>
              </div>
              <div className="flex space-x-2 my-auto">
                <p className=" text-lg">{item.rewardStatus}</p>
                <ChevronRight />
              </div>
            </CardDescription>
          </CardContent>
        </Card>
      ))}
    </>
  );
}

function RewardClaimTab() {
  const { user } = useAuth()

  useEffect(() => {
    if(!user) return 
    getApprovedProjectsForQuarter(user.emailId);
    getRewardClaims(user.emailId);
  }, []);

  const [searchParams] = useSearchParams();

  const {
    getRewardClaims,
    approvedProjects,
    getApprovedProjectsForQuarter,
    rewardClaims,
  } = useProjectStore();

  if (!rewardClaims && !approvedProjects) return <Spinner />;
  //@ts-ignore
  const totalReward = approvedProjects.reduce(
    (acc: number, item: ProjectResponseDto) => acc + item.rewardAmount,
    0
  );
  const date = new Date();
  const month = date.getMonth();
  const q = month < 3 ? 1 : month < 6 ? 2 : month < 9 ? 3 : month < 12 ? 4 : 1;

  return (
    <>
      {searchParams.get("requestId") ? (
        <RequestDetilsTab />
      ) : (
        <Div className=" pt-0 space-y-2">
          <P className="text-md font-normal">
            {" You have "}
            <span className="text-primary">{`₹${totalReward.toLocaleString("en-IN") ?? "0000"}`} </span>
            approved for Reward Claim in {`Q${q}`} {date.getFullYear()}
          </P>
          <Claims
            claimList={rewardClaims}
            emailId={user?.emailId}
            quarter={`Q${q}`}
            totalAmount={totalReward}
          />
          <div>
            <Label className="text-lg">Projects </Label>
          </div>
          <UserProjects projects={approvedProjects} />
        </Div>
      )}
    </>
  );
}

export default RewardClaimTab;
