import { useNavigate, Outlet } from "react-router-dom"
import Spinner from "src/components/ui/spinner"
import { useAuth } from "./useAuth"

 
export function AdminRoutes() {
    const {user} = useAuth()
    const nav = useNavigate()
 
    if(!user) return <Spinner/>
  return (
    <Outlet/>
  )
}