export const IndustryVerticals = [
  { value: "agriculture", label: "Agriculture" },
  { value: "automotive", label: "Automotive" },
  { value: "banking_and_finance", label: "Banking and Finance" },
  { value: "chemicals", label: "Chemicals" },
  { value: "construction", label: "Construction" },
  { value: "consumer_goods", label: "Consumer Goods" },
  { value: "education", label: "Education" },
  { value: "energy", label: "Energy" },
  { value: "entertainment", label: "Entertainment" },
  { value: "food_and_beverage", label: "Food and Beverage" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality_and_tourism", label: "Hospitality and Tourism" },
  { value: "information_technology", label: "Information Technology (IT)" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "media_and_communications", label: "Media and Communications" },
  { value: "mining", label: "Mining" },
  { value: "pharmaceuticals", label: "Pharmaceuticals" },
  { value: "real_estate", label: "Real Estate" },
  { value: "retail", label: "Retail" },
  { value: "telecommunications", label: "Telecommunications" },
  {
    value: "transportation_and_logistics",
    label: "Transportation and Logistics",
  },
  { value: "utilities", label: "Utilities" },
  { value: "aerospace_and_defense", label: "Aerospace and Defense" },
  { value: "biotechnology", label: "Biotechnology" },
  { value: "environmental_services", label: "Environmental Services" },
  { value: "legal_services", label: "Legal Services" },
  { value: "insurance", label: "Insurance" },
  {
    value: "government_and_public_sector",
    label: "Government and Public Sector",
  },
  {
    value: "nonprofit_and_social_services",
    label: "Nonprofit and Social Services",
  },
  {
    value: "professional_services",
    label: "Professional Services (Consulting, Accounting, etc.)",
  },
];
