import { ChevronRight } from 'lucide-react'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { ProjectResponseDto, } from 'src/components/Types'
import Div from 'src/components/typography/div'
import Lead from 'src/components/typography/lead'
import P from 'src/components/typography/p'
import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'src/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'
import { getDateWithMoment } from 'src/pages/dashboard/project/ProjectDetails'
import AddProjectDialog from './NewProject'
interface MyProjectsTableProps {
    projects: ProjectResponseDto[] | []
}

function MyProjectsTable(
    { projects }: MyProjectsTableProps
) {

    const [_, setSearchParams] = useSearchParams()

    if (projects.length <= 0) return <Card className='border-none shadow-none rounded-none rounded-b-xl'>
        <CardHeader><CardTitle></CardTitle></CardHeader>
        <CardContent className='space-y-4 '>
            <img src="/images/add.png" className='mx-auto' alt="" />

            <h2 className='text-3xl text-center'>You have not added any launched clients for <br /> review in this quarter!</h2>
            <CardDescription className='text-center leading-7'>Once your client goes live on AWS, you can submit their details here for an <br /> incentive powered by AWS and CloudZenia</CardDescription>
            <span className='w-full flex'>
                <AddProjectDialog className="mx-auto" title="Let's add a project" />
            </span>
        </CardContent>
        <CardFooter />
    </Card>


    return (
        <Div className='overflow-auto  rounded-b-xl  bg-white'>
            <Table  >
                <TableHeader className='bg-black p-12 h-12 '>
                    <TableRow className=''>
                        <TableHead className='text-white pl-6 rounded-l-md  ' >Project Name</TableHead>
                        <TableHead className='text-white text-center'>Submit Date</TableHead>
                        <TableHead className='text-white text-center'>Reviewed On</TableHead>
                        <TableHead className='text-white '>Deal Manager</TableHead>
                        <TableHead className='text-white w-[100px] text-center'>Status</TableHead>
                        <TableHead className='text-white rounded-r-md '></TableHead>
                    </TableRow>
                </TableHeader>
                {projects && projects.map((project: ProjectResponseDto) => (
                    <TableBody key={project.projectKey}>
                        <TableRow className='cursor-pointer' onClick={() => { setSearchParams({ projectKey: project.projectKey.toString() }) }}  >
                            <TableCell className='pl-6' >
                                <div>
                                    <P className='font-normal text-lg'>{project.projectTitle}</P>
                                    <Lead className=' text-sm'>{project.country}</Lead>
                                </div>
                            </TableCell>
                            <TableCell className='text-center'>
                                <P className=''>{moment(project.createdDate).format("DD/MM/YYYY")}</P>
                            </TableCell>
                            <TableCell className='text-center'>
                                <P className=''>{
                                    getDateWithMoment(project.reviewedDate)
                                }</P>
                            </TableCell>

                            <TableCell className='text-center'>
                                <P className=''>{project.createdBy}</P>
                            </TableCell>

                            <TableCell className='text-center'>
                                <P className=''><ProjectStatusC status={project.arpStatus} /></P>
                            </TableCell>
                            <TableCell className='w-5  ' onClick={() => { setSearchParams({ projectKey: project.projectKey.toString() }) }} >
                                <Button variant="ghost">
                                    <ChevronRight className='mx-auto' />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
           ))}
            </Table>
        </Div>
    )
}

export default MyProjectsTable

export const ProjectStatusC = ({ status } : any) => {

    const badgeElement = status === 'review' ?
        <p className='text-yellow-500 '>In Review</p> : status === 'rejected'?
            <p className='text-red-500'>Rejected</p> : status === 'approved' ?
                <p className='text-green-500'>Approved</p> : <>N/A</>
    return badgeElement
}