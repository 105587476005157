export function debounce(fn:any, duration:any){ 
  let timer:any = null
  return function (...args:any) { 

    if(timer) { 
      clearTimeout(timer);
      timer = null
    }
    timer = setTimeout(()=> {
      //@ts-ignore
      fn.apply(this,args);
    },duration)
  } 
}