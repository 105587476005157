import SearchComponent from 'src/components/custom/SearchComponent'
import Div from 'src/components/typography/div'
import { Card, CardTitle } from 'src/components/ui/card'
import { useProjectStore } from 'src/store/projectStore'
import CustomTable from './CustomTable'




function ProjectsTable({ projects }: any) {
    const { filteredProjects } = useProjectStore()
    return (
        <Div className=' '>
            <Card className='  border-none shadow-none p-6  w-full'>
                <div className='flex justify-between'>
                    <CardTitle className='text-lg font-normal'>Projects</CardTitle>
                    <SearchComponent />
                </div>
                <CustomTable projects={filteredProjects ?? projects} />

            </Card>
        </Div>
    )
}

export default ProjectsTable