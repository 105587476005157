
import { toast } from "sonner";
import {
  BankingDetailsDto,
  BankingDetailsReponseDto,
  CreateRewardClaimDto,
  ProjectResponseDto,
  QYFilters,
  RewardClaim,
  UpdateProjectStatus
} from "src/components/Types";
import { routes } from "src/lib/routes";
import { create } from "zustand";

interface IProjectStore {
  projects: Array<ProjectResponseDto> | [];
  project: ProjectResponseDto | null;
  totalProjectOverview: any;
  getProjectById: (id: string) => void;
  postProject: (data: any) => Promise<void>;
  getUserProjects: (emailId: string) => void;
  reset: () => void;

  quarter: string | null;
  year: string | null;
  monthFilter: () => void;
  setQuarterYear: (quarter: string, year: string) => void;

  filterProjectsWithTitle: (title: string) => void;
  filteredProjects: Array<ProjectResponseDto> | null;
  filterProjects: (filter: string) => void;
  getProjectsWithFilters: (data: QYFilters) => void;

  bankDetails: BankingDetailsReponseDto | null;
  postBankDetails: (data: BankingDetailsDto) => Promise<void>;
  getBankDetails: (emailId: string) => void;

  rewardClaims: Array<RewardClaim> | null;
  submitRewardRequest: (data:any) => Promise<void>

  rewardClaim: RewardClaim | null;
  approvedProjects: Array<ProjectResponseDto> | [];
  getApprovedProjectsForQuarter: (emailId: string) => Promise<void>;
  getRewardClaims: (emailId: string) => void;
  getRewardClaimByKey: (rewardKey: string) => void;
  
  isCreateClaimEligible: (email: string) => Promise<void>;

  totalPages: number;
  totalElements: number;
  page: number;
  size: number;
  filters: {
    arpStatus?: string;
  };
  setFilters: (data: any) => void;
  updatePageSize: (page?: number, size?: number) => void;
  updateProjectDetails: (data: ProjectResponseDto) => Promise<void>;
  getAllProjectsForAdmin: (data: any) => Promise<void>;
  resetAll: ()=> void
}

const initialState ={ 
  project: null,
  totalProjectOverview: null,
  projects: [],
  filteredProjects: null,bankDetails: null,
  quarter: null,
  year: null,
  rewardClaims: null,
  approvedProjects: [],
  totalElements: 0,
  totalPages: 0,
  page: 0,
  size: 10,
  filters: { arpStatus: "all" },
  rewardClaim: null,
}

export const useProjectStore = create<IProjectStore>((set, get) => ({
  ...initialState,
  reset() {
    set({ project: null, projects: [] });
  },
  resetAll() { 
    console.log('init')
    set({ ...initialState})
  },

  
  getProjectById: async (id: string) => {
    var project: ProjectResponseDto | undefined;
    project = get().projects.filter(
      (proj) => proj.projectKey === parseInt(id)
    )[0];
    if (project) set({ project });
    else
      try {
        const project = await routes.APIS.PROJECTS.GET_BY_Id(id);
        set({ project });
      } catch (error) {
        console.log("Erorr ", error);
      }
  },
 
  getUserProjects: async (emailId: string) => {
    try {
      const { projectResponseList, projectSummary } =
        await routes.APIS.PROJECTS.GET_ALL(emailId);
      set({
        projects: projectResponseList,
        totalProjectOverview: projectSummary,
      });
    } catch (error) {
      console.log("error occured", error);
    }
  },

  postProject: async (data: any) => {
    try {
      const res = await routes.APIS.PROJECTS.CREATE(data);
      const e = await routes.APIS.PROJECTS.GET_BY_Id(res.projectKey.toString());
      set({
        projects: [...get().projects, e],
        totalProjectOverview: {
          ...get().totalProjectOverview,
          totalProjectCount: get().totalProjectOverview.totalProjectCount + 1,
          arpReviewProjectCount:
            get().totalProjectOverview.arpReviewProjectCount + 1,
        },
      });
      toast.success("Thanks for submitting!!");
    } catch (error) {
      toast.error("Something went wrong");
      console.log("Could not get this up!!", error);
    }
  },

 
  filterProjects(filter) {
    if (filter === "all") {
      set({ filteredProjects: get().projects });
      return;
    }
    set({
      filteredProjects: get().projects?.filter(
        (proj) => proj.arpStatus === filter
      ),
    });
  },
  
  async postBankDetails(data) {
    const bankDetails = await routes.APIS.BANKING_DETAIL_CONTROLLER.CREATE(
      data
    );
    set({ bankDetails });
    toast.success("Details Posted Successfully!");
  },

  async getBankDetails(emailId) {
    const bankDetails = await routes.APIS.BANKING_DETAIL_CONTROLLER.GET_BY_ID(
      emailId
    );
    set({ bankDetails: bankDetails });
  },

  filterProjectsWithTitle(title) {
    set({
      filteredProjects: get().projects?.filter((project) =>
        project.projectTitle.toLowerCase().includes(title)
      ),
    });
  },
  
  monthFilter() {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let q = month < 3 ? 1 : month < 6 ? 2 : month < 9 ? 3 : month < 12 ? 4 : 1;

    set({
      quarter: `Q${q}`,
      year: String(year),
    });
  },

  setQuarterYear(quarter, year) {
    set({ quarter: quarter, year: year });
  },
  async getProjectsWithFilters(data: QYFilters) {
    try {
      const res = await routes.APIS.PROJECTS.GET_BY_Q_AND_Y(data);
      set({
        projects: res.projectResponseList,
        totalProjectOverview: res.projectSummary,
      });
    } catch (error) {}
  },

  

  async submitRewardRequest(data: CreateRewardClaimDto) {
      const res = await routes.APIS.REWARD_CLAIMS.CREATE(data).then((res)=> {
        get().getRewardClaims(data.emailId)
        toast.success('Claim created, Thank you!!')
      } ).catch(()=>{
        toast.error('')
      });
      return res;
  },

  async getRewardClaims(emailId) {
    const res = await routes.APIS.REWARD_CLAIMS.GET_REWARD_CLAIMLIST(emailId);
    set({ rewardClaims: res });
    return res;
  },
  
  async getRewardClaimByKey(rewardKey) {
    const res = await routes.APIS.REWARD_CLAIMS.GET_REWARD_CLAIM_BY_KEY(
      rewardKey
    );
    set({ rewardClaim: res });
  },
  async isCreateClaimEligible(email) {
    const res = await routes.APIS.REWARD_CLAIMS.IS_REWARD_CLAIM_ELIGIBLE(email);
    return res;
  },

  

  async getApprovedProjectsForQuarter(emailId) {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    //this brings the projects from one quarter before the current quarter
    let q = month < 3 ? 4 : month < 6 ? 1 : month < 9 ? 2 : month < 12 ? 3 : 4;

    const res = await routes.APIS.PROJECTS.GET_BY_Q_AND_Y({
      quarter: `Q${q}`,
      projectYear: `${year}`,
      emailId,
    });

    const pro = res.projectResponseList.filter((project:any)=> project.arpStatus === 'approved')
    set({ approvedProjects: pro });
  },


  async updateProjectDetails(data) {

      const obj: UpdateProjectStatus = {
        projectKey: data.projectKey,
        emailId: data.emailId,
        arpStatus: data.arpStatus,
        comments: data.comments,
        reviewedDate: data.reviewedDate,
        rewardAmount: data.rewardAmount,
        cashUnblocked: data.cashUnblocked,
        approvedDate: data.approvedDate,
      };
      let projects = get().projects.map((item) =>
        item.projectKey === obj.projectKey ? { ...item, ...obj}  : item
    );
    set({ projects: projects });
    routes.APIS.ADMIN.UPDATE_PROJECT_DETAILS(obj)
    .then(()=>toast.success("Update Successfull!!"))
    .catch((e)=>toast.success("Update Successfull!!"))
  },

  
  updatePageSize(page, size) {
    if (!size) set({ page, size: get().size });
    else set({ page, size });
  },

  async getAllProjectsForAdmin(data) {
    const res: any = await routes.APIS.ADMIN.GET_ALL_PROJECTS(data);
    set({
      projects: res.projectResponseList,
      totalProjectOverview: res.projectSummary,
      totalElements: res.totalElements,
      totalPages: res.totalPages,
    });
    if(res.totalPages < 2) { 
      set({page: 0 })
    }
  },

  setFilters(data) {
    set({ filters: { ...get().filters, ...data } });
  }
}));

export function convertTimestamp(inputTimestamp:string) {
  // Parse the input timestamp

  const isoDateTimeRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d\.\d{3}(?:[+-][01]\d:[0-5]\d|Z)/;

  if(isoDateTimeRegex.test(inputTimestamp)) return inputTimestamp
  const date = new Date(inputTimestamp);

  date.setUTCDate(date.getUTCDate()+1);
  date.setUTCHours(0, 0, 0, 0);

  return date.toISOString();
}
