import { Outlet } from "react-router-dom";
import { AuthProvider } from "./lib/auth";

function Layout() {
  return (
    <>
    <AuthProvider>
      <Outlet />
      </AuthProvider>
    </>
  );
}

export default Layout;
