import { Outlet, useNavigate } from 'react-router-dom'
import Spinner from 'src/components/ui/spinner'
import { useAuth } from './useAuth'

function SuperAdminRoutes() {
    const {user} = useAuth()
    const nav = useNavigate()
 
    if(!user) return <Spinner/>
  return (
    <Outlet/>
  )
}

export default SuperAdminRoutes