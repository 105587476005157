import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import PrivateRoute from "./lib/privateRoute";
import Dashboard from "./pages/dashboard/dashboard";
import Home from "./pages/Home";
import AdminLogin from "./pages/login/adminlogin";
import Login from "./pages/login/login";
import Register from "./pages/login/register";
import PageNotFoundError from "./views/PageNotFoundError";
import CashUnlocked from "./pages/dashboard/cash-unlocked/cash-unlocked";
import Claims from "./pages/dashboard/claims";
import Onboarding from "./pages/dashboard/onboarding/Onboarding";
import ViewProfileTab from "./pages/dashboard/profile/viewprofile";
import RewardClaimTab from "./pages/dashboard/reward-claim";
import SupportTab from "./pages/dashboard/support";
import UserInvitation from "./pages/dashboard/userInvitation";
import MyProjectsTab from "./pages/my-projects/my-projects-tab";
import Projects from "./views/admin-projects/projects";
import SuperAdminRoutes from "./lib/superAdminRoutes";
import { AdminRoutes } from "./lib/AdminRoutes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "*", element: <PageNotFoundError /> },
      { path: "/home", element: <Home /> },
      { path: "/login", element: <Login /> },
      { path: "/admin", element: <AdminLogin /> },
      { path: "/register", element: <Register /> },
      {
        path: "/",
        element: <PrivateRoute />,
        children: [
          {
            path: "/dashboard/",
            element: <Dashboard />,
            children: [
              { path: "/dashboard/projects", element: <MyProjectsTab /> },
              { path: "/dashboard/waiting", element: <UserInvitation /> },
              { path: "/dashboard/support", element: <SupportTab /> },
              { path: "/dashboard/rewards-claim", element: <RewardClaimTab /> },
              { path: "/dashboard/view-profile", element: <ViewProfileTab /> },
              { path: "/dashboard/cash-unlocked", element: <CashUnlocked /> },
              {
                path: "/dashboard/",
                element: <AdminRoutes />,
                children: [
                  {
                    path: "/dashboard/onboarding",
                    element: <Onboarding />,
                  }]
              },
              {
                path: "/dashboard/admin",
                element: <SuperAdminRoutes />,
                children: [
                  {
                    path: "/dashboard/admin/onboarding",
                    element: <Onboarding />,
                  },
                  { path: "/dashboard/admin/projects", element: <Projects /> },
                  { path: "/dashboard/admin/claims", element: <Claims /> },
                  {
                    path: "/dashboard/admin/view-profile",
                    element: <ViewProfileTab />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
