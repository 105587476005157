import { ChevronRight } from "lucide-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ProjectResponseDto } from "src/components/Types";
import Lead from "src/components/typography/lead";
import P from "src/components/typography/p";
import { Button } from "src/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { useProjectStore } from "src/store/projectStore";

function CustomTable(props: any) {
  const { projects } = useProjectStore();
  const navigate = useNavigate();

  let p = props.projects ?? projects;

  return (
    <div className="mt-6">
      <Table>
        <TableHeader className="bg-black p-12 h-12 ">
          <TableRow>
            <TableHead className="text-white pl-6 rounded-l-md  ">
              Project Name
            </TableHead>
            <TableHead className="text-white">Review Date</TableHead>
            <TableHead className="text-white">Rewards</TableHead>
            <TableHead className="text-white rounded-r-md "></TableHead>
          </TableRow>
        </TableHeader>
        {p &&
          p.map((project: ProjectResponseDto) => (
            <>
              <TableBody>
                <TableRow>
                  <TableCell className="pl-6">
                    <div>
                      <P className="font-normal text-lg">
                        {project.projectTitle}
                      </P>
                      <Lead className=" text-sm">{project.country}</Lead>
                    </div>
                  </TableCell>
                  <TableCell>
                    <P className="">
                      {moment(project.reviewedDate).format("DD/MM/YYYY")}
                    </P>
                  </TableCell>
                  <TableCell className="w-40">
                    {" "}
                    ₹ {project?.rewardAmount?.toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(
                        `/dashboard/projects?projectKey=${project.projectKey.toString()}`
                      );
                    }}
                    className="w-20 "
                  >
                    <Button variant="ghost">
                      <ChevronRight className="mx-auto" />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          ))}
      </Table>
    </div>
  );
}

export default CustomTable;
