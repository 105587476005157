import { Link, useLocation } from 'react-router-dom'

type HeaderRoute = { label: string, href: string }

function Header({ user }: any) {

    const routes: HeaderRoute[] = [
        { label: 'Home', href: '/home' },
        { label: 'About Us', href: '/about-us' },
        { label: 'Our Services', href: '/our-services' },
        { label: 'Contact Us', href: '/contact-us' }]

    const location = useLocation()

    const RouteComp = ({ route }: any) => {
        console.log('path', location)
        if (route.href === location.pathname) return <Link to="/" ><h6 className='text-[#00A0D6] font-stolzl-r'>{route.label}</h6></Link>
        return <Link to="/"  ><h6 className='text-[#585858] font-stolzl'>{route.label}</h6></Link>
    }

    return (

        <div className='flex  justify-between items-center mx-12 my-4 max-w-7xl mx-auto'>
            <div>
                <img width={'75'} height={'75'} src="cloudzenia.webp" alt="" />
                </div>
            <div className='flex gap-14 '> {routes.map((route: HeaderRoute) =>
                <RouteComp route={route} />
            )}</div>
        </div>
    )
}

export default Header

