import { PopoverClose } from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Label } from "src/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Textarea } from "src/components/ui/textarea";
import { useClaimStore } from "src/store/claimStore";
import { useProjectStore } from "src/store/projectStore";

function RewardStatus(props: any) {
  const { claim } = props;
  const { quarter } = useProjectStore();
  const { updateClaimStatus } = useClaimStore();
  const form = useForm({
    defaultValues: {
      rewardStatus: claim?.rewardStatus ?? "created",
      comments: claim.comments ?? "",
      quarter: quarter,
    },
  });

  useEffect(()=>{
      form.reset(claim)
  },[claim])

  function createRewardClaim(e: any) {
    const newRewardOjb = {
      ...e,
      emailId: claim.emailId,
      rewardKey: claim.rewardKey,
      totalAmount: claim.totalAmount
    };
    updateClaimStatus(newRewardOjb);
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={` 
         min-w-[125px]
  ${
    claim?.rewardStatus === "created"
      ? "bg-[#FFBA08] hover:bg-[#FFBA08] text-white"
      : claim?.rewardStatus === "businessApproved"
      ? " hover:bg-[#F35325] bg-[#F35325] text-white"
      : claim?.rewardStatus === "financeApproved"
      ? "bg-[#05A6F0] hover:bg-[#05A6F0]  text-white"
      : claim?.rewardStatus === "completed"
      ? "hover:bg-[#81BC00] bg-[#81BC00] text-white"
      : "bg-[#FFBA08]  hover:bg-[#FFBA08] text-white"
  }   
            `}
        ><div className="flex justify-between items-center "> <span>
          {claim?.rewardStatus === "created"
            ? "CREATED"
            : claim?.rewardStatus === "businessApproved"
            ? "BUSINESS"
            : claim?.rewardStatus === "financeApproved"
            ? "FINANCE"
            : claim?.rewardStatus === "completed"
            ? "COMPLETED"
            : "CREATED"}
</span>
          <ChevronDown /></div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-100">
        <div className="grid gap-4">
          <div className=" flex justify-between items-center gap-4">
            <div>
              {" "}
              <h4 className="font-medium leading-none">Reward Claim</h4>
              <p className="text-sm text-muted-foreground">
                Create Reward claim and update status.
              </p>
            </div>
          </div>
          <Form {...form}>
            {" "}
            <form onSubmit={form.handleSubmit(createRewardClaim)}>
              <div className="grid gap-2 grid-cols-2">
                <div>
                  <FormField
                    control={form.control}
                    name="rewardStatus"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Claim Status</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger
                            className={`w-[180px]  
                                    
  ${
    field.value === "created"
      ? "bg-[#FFBA08] hover:bg-[#FFBA08] text-white"
      : field.value === "businessApproved"
      ? " hover:bg-[#F35325] bg-[#F35325] text-white"
      : field.value === "financeApproved"
      ? "bg-[#05A6F0] hover:bg-[#05A6F0]  text-white"
      : field.value === "completed"
      ? "hover:bg-[#81BC00] bg-[#81BC00] text-white"
      : "bg-[#FFBA08]  hover:bg-[#FFBA08] text-white"
  }   
                            `}
                          >
                            <FormControl>
                              <>
                                <SelectValue /> <ChevronDown />
                              </>
                            </FormControl>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {Object.values([
                                "created",
                                "businessApproved",
                                "financeApproved",
                                "completed",
                              ]).map((item) => (
                                <SelectItem value={item}>
                                  {"created" === item
                                    ? "CREATED"
                                    : "businessApproved" === item
                                    ? "BUSINESS"
                                    : "financeApproved" === item
                                    ? "FINANCE"
                                    : "COMPLETED"}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormDescription />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className=" col-span-2">
                  <Label>Comment</Label>
                  <FormField
                    control={form.control}
                    name="comments"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel />
                        <FormControl>
                          <Textarea rows={3} placeholder="000" {...field} />
                        </FormControl>
                        <FormDescription />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className=" text-end">
                <PopoverClose>
                <Button>Update Claim</Button>
                </PopoverClose>
              </div>
            </form>
          </Form>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default RewardStatus;
