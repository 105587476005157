import { ChevronDown } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { useProjectStore } from "src/store/projectStore";

const arpStatus ={ 
    REJECTED : "rejected",
    APPROVED: "approved",
    REVIEW : "review",
    ALL : 'all',
}

function StatusFilter() {
  const { filters, setFilters } = useProjectStore();
  
  return (
    <Select  onValueChange={(e)=> {
        setFilters({arpStatus: e})
    }} defaultValue={filters?.arpStatus ?? 'all'}>
      <SelectTrigger className={` text-white `}>
        <SelectValue />
        <ChevronDown />
      </SelectTrigger>
      <SelectContent  >
        <SelectGroup>
          {Object.values(arpStatus).map((item) => (
        <SelectItem value={item} >{item.toUpperCase()}</SelectItem>  ))
          }
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default StatusFilter;
