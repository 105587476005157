import { zodResolver } from '@hookform/resolvers/zod'
import { Send } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { Button } from 'src/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'src/components/ui/form'
import { Input } from 'src/components/ui/input'
import { routes } from 'src/lib/routes'
import { z } from 'zod'

const emailSchema = z.object({
    emailId: z.string()
        .min(1, { message: "This field has to be filled." })
        .email("This is not a valid email.").toLowerCase()
})

function Onboarding({ user }: any) {

    const form = useForm<z.infer<typeof emailSchema>>({
        resolver: zodResolver(emailSchema),
        defaultValues: {
            emailId: ''
        }
    })

    async function onSubmit(e: z.infer<typeof emailSchema>) {

        await routes.APIS.EMAIL_CONTROLLER.SEND_ONBOARDING_EMAIL({ emailId: e.emailId }).then(() => {
            toast.success("Onboarding mail successfully sent!!")
        }).catch(() => {
            toast.error('Something went wrong!!')
        })
    }

    return (
        <div className='w-full flex-1 p-6 pt-0'>
            <h2 className='w-full  text-center font-bold text-xl'>User Onboarding Dashboard</h2>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name='emailId'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <div className='flex gap-2'>
                                    <FormControl >
                                        <Input placeholder='user@email.com' {...field} />
                                    </FormControl>
                                    <Button type="submit"><Send /></Button>
                                </div>
                                <FormDescription> Invite Users to the Partnership</FormDescription>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </div>
    )
}

export default Onboarding