import { zodResolver } from '@hookform/resolvers/zod'
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { CheckIcon, ChevronDown, Pencil } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import Div from 'src/components/typography/div'
import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'src/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'src/components/ui/form'
import { Input } from 'src/components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { Textarea } from 'src/components/ui/textarea'
import { routes } from 'src/lib/routes'
import { useAuth } from 'src/lib/useAuth'
import { z } from 'zod'
import AddBankingDetails from './AddBankingDetails'

import { LOCAL_STORAGE_KEYS } from 'src/components/Types'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/components/ui/command'
import Spinner from 'src/components/ui/spinner'
import { cn } from 'src/lib/utils'
import { CountryList } from 'src/views/myprojects/countryList'


export function Capitalise(data: string) {
    if (!data) return "userName"
    return data[0]?.toUpperCase() + data?.substring(1)
}

const formSchema = z.object({
    emailId: z.string().email("Enter a valid EmailId").toLowerCase(),
    // password: z.string().min(8, "Password should be atleast 8 characters"),
    mobileNumber: z.string().min(10, "Mobile should be 10 digits").max(10, "Number should not be  more than 10 digits"),
    firstName: z.string().min(3).max(25, 'Names should be under 25 characters'),
    lastName: z.string().min(3).max(25, 'Names should be under 25 characters'),
    addressLine1: z.string().min(3).max(220, "Please Address length under 220 characters"),
    addressLine2: z.string().min(3).max(220, "Please Address length under 220 characters"),
    state: z.string().min(3),
    roles: z.string().min(4, "Title of your roles in the company"),
    pinCode: z.string().min(4, 'Pin Code Must be more than 4 Digits').max(8, "Pin Code should be less than 8 Digits"),
    country: z.string().min(4),
    userCategory: z.string().min(2),
})

function UserDetailsCard({user, auth}:any) {


    const [edit, setEdit] = useState(true)
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            emailId: user.emailId ?? '',
            // password: "",
            mobileNumber: user.mobileNumber ?? '',
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            addressLine1: user.addressLine1 ?? '',
            addressLine2: user.addressLine2 ?? '',
            state: user.state ?? '',
            roles: user.roles ?? '',
            pinCode: user.pinCode ?? '',
            country: user.country ?? '',
            userCategory: user.userCategory ?? '',
        }
    })

    function onSubmit(values: any) {
        routes.APIS.AUTHENTICATION.SIGN_UP(values)
            .then((res) => {

                
                localStorage.removeItem(LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP)
                localStorage.setItem('CLOUDZENIA_APN_PARTNERSHIP', JSON.stringify(res))
                toast.success("Updated Successfully");
                setTimeout(()=> {auth.setUser(res)},100)
            }).catch((err) => {
                console.log('updating user ', err);
                toast.error("Error occured while updating User")

            })
        setEdit(true)
        
    }
    return <Card className='flex-1'>
        <CardHeader className='flex flex-row items-center justify-between '>
            <CardTitle className='text-2xl'>User Detail</CardTitle>
            <Button

                onClick={() => setEdit(!edit)}
                variant={edit ? 'outline' : 'secondary'}><Pencil /></Button>
        </CardHeader>
        <CardContent>
            <CardDescription >
                <Form  {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)} >
                        <Div className="grid grid-cols-2 gap-6 bg-white">
                            <FormField
                                control={form.control}
                                name="emailId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Email Id
                                        </FormLabel>
                                        <FormControl >
                                            <Input disabled={true}
                                                placeholder="Email Id" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField 
                            disabled
                                // control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem>

                                        <FormLabel className='flex gap-4 '>
                                            Password  <CardDescription> *primary email login</CardDescription>
                                        </FormLabel>
                                        <FormControl >
                                            <Input disabled={edit}
                                                type='password'
                                                placeholder="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />

                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            First Name
                                        </FormLabel>
                                        <FormControl>
                                            <Input disabled={edit} placeholder="first name " {...field}
                                                onChange={(e) => form.setValue('firstName', e.target.value.replace(new RegExp('[^a-zA-Z]'), ''))} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />

                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Last Name
                                        </FormLabel>
                                        <FormControl>
                                            <Input disabled={edit} placeholder="Last Name" {...field}

                                                onChange={(e) => form.setValue('lastName', e.target.value.replace(new RegExp('[^a-zA-Z]'), ''))} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField
                                control={form.control}
                                name="mobileNumber"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Mobile Number
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                disabled={edit}
                                                type={'text'} {...field} onChange={(e) => form.setValue('mobileNumber', e.target.value.replace(new RegExp('[^0-9]'), ''))}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField

                                control={form.control}
                                name="addressLine1"
                                render={({ field }) => (
                                    <FormItem className="col-span-2">
                                        <FormLabel>
                                            Address Line 1
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea disabled={edit} placeholder="Address 1" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />

                            <FormField
                                control={form.control}
                                name="addressLine2"
                                render={({ field }) => (
                                    <FormItem className="col-span-2">
                                        <FormLabel>
                                            Address Line 2
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea disabled={edit} placeholder="Addres Line 2 " {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />

                            <FormField
                                control={form.control}
                                name="state"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            State
                                        </FormLabel>
                                        <FormControl>
                                            <Input defaultValue={field.value} disabled={edit} placeholder="State " {...field}
                                                onChange={(e) => form.setValue('state', e.target.value.replace(new RegExp('[^a-zA-Z]'), ''))} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField
                                control={form.control}
                                name="pinCode"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Pin Code
                                        </FormLabel>
                                        <FormControl>
                                            <Input disabled={edit} placeholder="pincode" {...field} onChange={(e) => form.setValue('pinCode', e.target.value.replace(new RegExp('[^0-9]'), ''))} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField
                                control={form.control}
                                name="country"
                                render={({ field }) => (
                                    <FormItem className="flex flex-col ">
                                        <FormLabel className="font-stolzl-r " >Country <span className='text-red-500'>*</span></FormLabel>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <FormControl>
                                                    <Button disabled={edit}
                                                        {...field}
                                                        variant="outline"
                                                        role="combobox"
                                                        className={cn(
                                                            " justify-between font-stolzl  ",
                                                            !field.value && "text-muted-foreground"
                                                        )}
                                                    >
                                                        {field.value === '' ? "Country" : field.value}
                                                        <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                                                    </Button>
                                                </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent className="max-w-[300px] p-0">
                                                <Command>
                                                    <CommandInput
                                                        placeholder="search..."
                                                        className="h-9"
                                                    />
                                                    <CommandEmpty>No country found.</CommandEmpty>
                                                    <CommandGroup>
                                                        <PopoverClose>
                                                            <CommandList>
                                                                {CountryList.map((item: any) => (
                                                                    <CommandItem
                                                                        className='text-left'
                                                                        value={item.shortName}
                                                                        key={item.shortName}
                                                                        onSelect={() => form.setValue("country", item.shortName)}
                                                                    > {item.shortName}
                                                                        <CheckIcon
                                                                            className={cn(
                                                                                "ml-auto h-4 w-4",
                                                                                item.shortName === field.value
                                                                                    ? "opacity-100"
                                                                                    : "opacity-0"
                                                                            )}
                                                                        />
                                                                    </CommandItem>
                                                                ))}
                                                            </CommandList>
                                                        </PopoverClose>
                                                    </CommandGroup>
                                                </Command>
                                            </PopoverContent>
                                        </Popover>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="roles"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Roles
                                        </FormLabel>
                                        <FormControl>
                                            <Input disabled={edit} placeholder='Role' {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                            <FormField
                                control={form.control}
                                name="userCategory"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            user category
                                        </FormLabel>
                                        <FormControl>
                                            <Select disabled={edit} onValueChange={field.onChange} {...field}>
                                                <SelectTrigger className="">
                                                    <SelectValue placeholder="Select Category" />
                                                    <ChevronDown />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectItem value="company">Company</SelectItem>
                                                        <SelectItem value="freelancer">Freelancer</SelectItem>
                                                        <SelectItem value="etc">Etc</SelectItem>
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )} />
                        </Div>

                        {!edit && <Button type="submit" className="mt-2">Submit</Button>}
                    </form>
                </Form >
            </CardDescription>
        </CardContent>
    </Card>
}

function ViewProfileTab() {
    const auth = useAuth()
    if(!auth.user) return <Spinner/>
    return (
        <Div className='p-4 space-y-6'>
            <AddBankingDetails />
            <UserDetailsCard user={auth.user} auth={auth} />
        </Div>
    )
}

export default ViewProfileTab