import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectResponseDto } from "src/components/Types";
import { Input } from "src/components/ui/input";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "src/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { debounce } from "src/lib/helper";
import ProjectDetails from "src/pages/dashboard/project/ProjectDetails";
import { useProjectStore } from "src/store/projectStore";
import Status from "./cells/status";
import StatusFilter from "./StatusFilter";

export function paginate(
  currentPage: number,
  totalPages: number,
  displayRange: number = 5
) {
  let pages = [];
  if (totalPages <= displayRange) {
    return Array.from({ length: totalPages }, (_, i) => i);
  }
  pages.push(0);
  if (currentPage > 2) {
    pages.push("...");
  }
  const start = Math.max(1, currentPage - Math.floor(displayRange / 2));
  const end = Math.min(
    totalPages - 1,
    currentPage + Math.floor(displayRange / 2)
  );
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  if (currentPage < totalPages - 1) {
    pages.push("...");
  }
  pages.push(totalPages - 1);
  const p = new Set(pages);
  return Array.from(p);
}
interface ActionTableProps {
  projects?: ProjectResponseDto[];
}

export function ProjectsTable(props: ActionTableProps) {
  const [emailId, setEmailId] = useState();
  const {
    page,
    year,
    filters,
    quarter,
    size,
    projects,
    getAllProjectsForAdmin,
  } = useProjectStore();
  const { updateProjectDetails } = useProjectStore();
  const [searchParams, setSearchParams] = useSearchParams();

  function updateProject(data: any, index: number) {
    updateProjectDetails(data);
  }

  useEffect(() => {
    if (!quarter) return;

    getAllProjectsForAdmin({
      page,
      size,
      projectYear: year,
      quarter: quarter,
      arpStatus: filters?.arpStatus,
      emailId: emailId,
    });
  }, [year, quarter, page, size, filters.arpStatus, emailId]);

  function handleEmailSearch(e: any) {
    setEmailId(e.target.value);
  }

  return searchParams.get("projectKey") ? (
    <ProjectDetails />
  ) : (
    <div>
      <Table className="">
        <TableHeader className="bg-black  ">
          <TableHead className=" rounded-s-md pl-4 text-white ">
            Project Key
          </TableHead>
          <TableHead className="text-white">Title</TableHead>
          <TableHead className="text-white">
            <Input
              onChange={debounce(handleEmailSearch, 300)}
              placeholder={"Email"}
              className="  placeholder:text-white placeholder:font-extrabold font-extrabold "
            />
          </TableHead> 

          <TableHead className="text-white ">MRR</TableHead>
          <TableHead className="text-white">
            <StatusFilter />
          </TableHead>
          <TableHead className="text-white ">Created</TableHead>
          <TableHead className="text-white ">Review</TableHead>
          <TableHead className="text-white text-center ">Approved</TableHead>
        </TableHeader>
        {Boolean(projects.length) && (
          <TableBody>
            {projects.map((project: ProjectResponseDto, index: number) => (
              <TableRow
                className="hover:bg-[#00000010] hover:cursor-pointer"
                key={project.projectKey}
              >
                <TableCell className={"text-center "}>
                  {project.projectKey}
                </TableCell>
                <TableCell
                  className="hover:underline"
                  onClick={() =>
                    setSearchParams({ projectKey: `${project?.projectKey}` })
                  }
                >
                  {project.projectTitle}
                </TableCell>
                <TableCell>{project.emailId}</TableCell>
                <TableCell>$ {project.monthlyBillInUsd}</TableCell>
                <TableCell>
                  <Status
                    updateProject={updateProject}
                    index={index}
                    project={project}
                  />
                </TableCell>
                <TableCell>
                  {project.createdDate
                    ? new Date(project?.createdDate).toLocaleDateString()
                    : "--/--/--"}
                </TableCell>
                <TableCell>
                  {project.reviewedDate
                    ? new Date(project?.reviewedDate).toLocaleDateString()
                    : "--/--/--"}
                </TableCell>
                <TableCell className="text-center">
                  {project?.approvedDate
                    ? new Date(project.approvedDate).toLocaleDateString()
                    : "--/--/--"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {Boolean(!projects?.length) && (
        <div className=" text-center p-6">Welp, No projects found!!</div>
      )}
      <Paginate />
    </div>
  );
}

function Paginate(props: any) {
  const { page, totalPages, updatePageSize } = useProjectStore();
  const [pages, setPages] = useState<Array<string | number>>([]);

  useEffect(() => {
    if (totalPages < 0) return;
    setPages(paginate(page, totalPages));
  }, [page, totalPages]);

  console.log(pages);
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem
          onClick={() => {
            updatePageSize(page - 1);
          }}
          hidden={page == 0}
        >
          <PaginationPrevious href="#" />
        </PaginationItem>
        {pages.length > 1 &&
          pages.map((_: any, index: number) => (
            <PaginationItem
              onClick={() => {
                if (typeof _ === "number") {
                  updatePageSize(_);
                }
              }}
            >
              <PaginationLink isActive={_ === page} href="#">
                {_ === "..." ? _ : _ + 1}
              </PaginationLink>
            </PaginationItem>
          ))}

        <PaginationItem
          hidden={page >= totalPages - 1}
          onClick={() => {
            updatePageSize(page + 1);
          }}
        >
          <PaginationNext href="#" />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
