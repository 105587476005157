import { zodResolver } from "@hookform/resolvers/zod";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import { CheckCircle2, ChevronDown, Image, X } from "lucide-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { UseFormReturn, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import ColorCard from "src/components/typography/colorcard";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "src/components/ui/alert-dialog";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { MultiSelect } from "src/components/ui/multi-select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Table, TableCell, TableRow } from "src/components/ui/table";
import { Textarea } from "src/components/ui/textarea";
import { routes } from "src/lib/routes";
import { useAuth } from "src/lib/useAuth";
import { cn } from "src/lib/utils";
import { useProjectStore } from "src/store/projectStore";
import { z } from "zod";

import { PopoverClose } from "@radix-ui/react-popover";
import Spinner from "src/components/ui/spinner";
import { AwsServicesList } from "./awsServicesList";
import CountrySelect from "./countryFormObject";
import { IndustryVerticals } from "./industryVerticalsList";

const addprojectSchema = z.object({
  customerCompanyName: z.string().min(1, "Please enter company name").max(50),
  customerWebsite: z.string().url("Please enter proper url"),
  industrialVertical: z.string().min(1, "Please select Industry vertical"),
  country: z.string().min(2, "Please select a country"),
  postalCode: z
    .string()
    .min(4, "Please enter Postal Code")
    .max(8, "Post Code should be 8 Digits"),
  projectTitle: z.string().min(1, "Please enter Project title").max(50),
  aboutBusiness: z
    .string()
    .min(20, "Please explain the business more")
    .max(1800),
  custBusinessProblem: z
    .string()
    .min(20, "Please explain business problem more")
    .max(1800),
  implementedSolution: z.string().min(20, "Please explain in Detail").max(1800),
  awsAccountId: z
    .string()
    .min(12, "AWS acconut number must be 12 digits")
    .max(12, "AWS account number must be 12 digit"),
  awsInventories: z
    .array(z.string())
    .min(1, "Please add services used for the Project"),
  // customerFindingSource: z.string().min(2).max(50),
  awsCustomerSinceMonth: z.string().min(1, "Please select a Month"),
  awsCustomerSinceYear: z.string().min(1, "Please select a Year"),
  projectStatus: z.string().min(2).max(50),
  monthlyBillInUsd: z.string().min(1, "Please Enter Monthly Aws bill in USD"),
  documentList: z
    .array(
      z.object({
        awsAccountId: z.string(),
        documentKey: z.number(),
        emailId: z.string(),
        s3Url: z.string(),
      })
    )
    .optional(),
  additionalDocumentPath: z.string(),
  flexiField1: z
    .string()
    .min(3, "Please Enter you Company Address!!")
    .max(250, "Please keep the text under 250 characters"),
});

async function Upload(data: any) {
  return await routes.APIS.UPLOAD_IMAGE.UPLOAD(data);
}
async function deleteImage(docuemntKey: string) {
  return await routes.APIS.UPLOAD_IMAGE.DELELE(docuemntKey);
}
function UploadInvoiceImages({ form }: { form: UseFormReturn<any> }) {
  const { user } = useAuth();
  const nav = useNavigate();
  const [file, setFile] = useState<any>();
  const [filelist, setFileList] = useState<Array<File> | null>(null);
  const { append, remove } = useFieldArray({
    control: form.control,
    name: "documentList",
  });
  function UploadFile(file: File) {
    if (!filelist) {
      setFileList([file]);
    } else {
      setFileList([...filelist, file]);
    }
    return true;
  }

  useEffect(() => {
    if (file) {
      if (form.watch("awsAccountId").length !== 12)
        return form.setFocus("awsAccountId");

      if (!user?.emailId) {
        toast.warning("Please complete your details");
        nav("/dashboard/view-profile");
        return;
      }
      const f = new FormData();
      toast.warning("Uploading image", file.name);
      f.append("file", file);
      f.append("emailId", user.emailId);
      f.append("awsAccountId", form.getValues("awsAccountId"));
      Upload(f)
        .then((res: any) => {
          const data: {
            awsAccountId: string;
            documentKey: number;
            emailId: string;
            s3Url: string;
          } = JSON.parse(res);

          if (data?.s3Url) {
            toast.success("Image Uploaded Successfully");
            append(data);
            UploadFile(file);
          }
        })
        .catch((err) => console.log("upload failed", err));
    }
  }, [file]);

  function handleRemoveImage(index: number, image: any) {
    remove(index);
    if (!filelist) return;
    setFileList(filelist.filter((item) => item.name !== image.name));
    deleteImage(image.docuemntKey).then((res) =>
      toast.success("Image deleted")
    );
  }

  return (
    <FormField
      control={form.control}
      name="documentList"
      render={({ field }) => (
        <FormItem className="col-span-2 text-end ">
          <FormLabel className="font-stolzl-r mb-2  text-start  text-md">
            <p>
              {"Upload Screenshot or invoice "}
              <span className="text-red-500">{"(optional)"}</span>
            </p>
          </FormLabel>
          <FormControl>
            <div className="p-3  flex border rounded-full  items-center relative  space-x-4">
              <Image className="text-primary ml-3" />
              <Input
                className="font-stolzl opacity-0  absolute"
                placeholder="Choose Image"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                  if (e.target.files) setFile(e.target.files[0]);
                }}
                type="file"
              />
              <p className="font-stolzl">Choose Image</p>
            </div>
          </FormControl>
          <Table>
            {filelist &&
              filelist.map((image, index) => (
                <TableRow>
                  <TableCell>{image.name}</TableCell>
                  <TableCell>
                    {Math.round(image.size / 10000) / 100 + "mb"}
                  </TableCell>
                  <TableCell>{<CheckCircle2 />}</TableCell>
                  <TableCell>
                    {
                      <CrossCircledIcon
                        onClick={() => handleRemoveImage(index, image)}
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
          </Table>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function yearsFromNow() {
  const date = new Date().getFullYear();
  const a = Array(25)
    .fill("")
    .map((_, index) => (
      <SelectItem value={`${date - 10 + index}`}>
        {date + index - 10}
      </SelectItem>
    ));
  return a;
}
export const AddProjectForm = forwardRef(({ handleClose }: any, ref) => {

  const { user } = useAuth();
  const nav = useNavigate();

  const form = useForm<z.infer<typeof addprojectSchema>>({
    resolver: zodResolver(addprojectSchema),
    defaultValues: {
      customerCompanyName: "",
      customerWebsite: "",
      industrialVertical: "",

      country: "",
      postalCode: "",
      projectTitle: "",
      aboutBusiness: "",
      custBusinessProblem: "",
      implementedSolution: "",
      awsAccountId: "",
      awsInventories: [],
      // customerFindingSource: "",
      awsCustomerSinceMonth: "",
      awsCustomerSinceYear: "",
      projectStatus: "",
      monthlyBillInUsd: "",
      additionalDocumentPath: "",
      documentList: [],
      flexiField1: "",
    },
    mode: "all",
  });
  useImperativeHandle(ref, () => {
    return {
      onSubmit: form.handleSubmit(submitForm),
    };
  });

  const { postProject } = useProjectStore();

  function submitForm(e: any) {
    if (!user) return toast.warning("Login please");
    if (!user.emailId) {
      toast.error("Please set up your EmailId");
      return nav("dashboard/view-profile");
    }
    const date = new Date();
    const month = date.getMonth();
    const q = month < 3 ? 1 : month < 6 ? 2 : month < 9 ? 3 : month < 12 ? 4 : 1;

    e.emailId = user.emailId;
    e.quarter = `Q${q}`;
    e.projectYear = date.getFullYear();
    e.awsInventories = e.awsInventories.reduce(
      (acc: string, item: string) => acc + item + ", ",
      ""
    );

    postProject(e)
      .then(() => {
        handleClose("close");
      })
      .catch((err) => {
        handleClose("close");
        toast.success('Opps, please try again later!!')
        console.log(err);
      });
  }

  return (
    <Form {...form}>
      <form className=" grid gap-6 p-2  grid-cols-2 ">
        <FormField
          control={form.control}
          name="customerCompanyName"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="font-stolzl-r  text-md">
                Company Name <span className="text-red-500">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  className="rounded-full p-6 font-stolzl"
                  placeholder="Enter Company Name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="flexiField1"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r text-md">
                Company Address <span className="text-red-500">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  className="rounded-full p-6 font-stolzl"
                  placeholder="Enter Company Address "
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <CountrySelect form={form} />
        <FormField
          control={form.control}
          name="postalCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                Postal Code
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Postal Code"
                  {...field}
                  className="p-6  rounded-full"
                  onChange={(e) =>
                    form.setValue(
                      "postalCode",
                      e.target.value.toLocaleUpperCase()
                    )
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="customerWebsite"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r text-md">
                Customer Website <span className="text-red-500">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  className="rounded-full p-6 font-stolzl"
                  placeholder="Enter Website name "
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="industrialVertical"
          render={({ field }) => (
            <FormItem className="flex flex-col ">
              <FormLabel className="font-stolzl-r  text-md">
                Industry Verticals
              </FormLabel>
              <Popover modal={true}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "p-6 font-stolzl rounded-full justify-between",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value
                        ? IndustryVerticals.find(
                            (item) => item.value === field.value
                          )?.label
                        : "Select"}
                      <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className=" w-[400px]  p-0">
                  <Command>
                    <CommandInput placeholder="search..." className="h-9" />
                    <CommandEmpty>No framework found.</CommandEmpty>
                    <PopoverClose>
                      <CommandList>
                        {IndustryVerticals.map((item: any) => (
                          <CommandItem
                            className="text-left"
                            value={item.value}
                            key={item.value}
                            onSelect={() =>{
                              form.setValue("industrialVertical", item.value)
                              form.clearErrors('industrialVertical')
                            }}
                          >
                            {item.label}
                          </CommandItem>
                        ))}
                      </CommandList>
                    </PopoverClose>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* <FormField
                control={form.control}
                name="customerCompanyWebsiteUrl"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="font-stolzl-r  text-md"  >
                            Customer Company Website Url
                        </FormLabel>
                        <FormControl>
                            <Input placeholder="website url"  {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            /> */}
        <FormField
          control={form.control}
          name="projectTitle"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className="font-stolzl-r  text-md">
                Project Title
              </FormLabel>
              <FormControl>
                <Input
                  className="rounded-full p-6 font-stolzl"
                  placeholder="Project Title"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="aboutBusiness"
          render={({ field }) => (
            <FormItem className=" col-span-2">
              <FormLabel className="font-stolzl-r  text-md">
                About Business
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="About..."
                  className=" font-stolzl rounded-3xl"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="custBusinessProblem"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className="font-stolzl-r  text-md">
                Customer Business Problem
              </FormLabel>
              <FormControl>
                <Textarea
                  className=" font-stolzl rounded-3xl"
                  placeholder="customer Business Problem"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="implementedSolution"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className="font-stolzl-r  text-md">
                Implemented Solution
              </FormLabel>
              <FormControl>
                <Textarea
                  className=" font-stolzl rounded-3xl"
                  placeholder="Implemented Solution"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="awsAccountId"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                AWS Account ID
              </FormLabel>
              <FormControl>
                <Input
                  className="rounded-full p-6"
                  type={"text"}
                  placeholder="Account Id"
                  {...field}
                  maxLength={12}
                  onChange={(e) =>
                    form.setValue(
                      "awsAccountId",
                      e.target.value.replace(new RegExp("[^0-9]"), "")
                    )
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="awsInventories"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                Select AWS Services
              </FormLabel>
              <MultiSelect
                selected={field.value}
                options={AwsServicesList}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="awsCustomerSinceMonth"
          render={({ field }) => (
            <FormItem className="mx-1">
              <FormLabel className="font-stolzl-r  text-md">
                AWS Customer Since Month
              </FormLabel>

              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="font-stolzl p-6 rounded-full text-gray-500">
                    <SelectValue placeholder="Select a Month" />
                    <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="january">January</SelectItem>
                    <SelectItem value="february">February</SelectItem>
                    <SelectItem value="march">March</SelectItem>
                    <SelectItem value="april">April</SelectItem>
                    <SelectItem value="may">May</SelectItem>
                    <SelectItem value="june">June</SelectItem>
                    <SelectItem value="july">July</SelectItem>
                    <SelectItem value="august">August</SelectItem>
                    <SelectItem value="september">September</SelectItem>
                    <SelectItem value="october">October</SelectItem>
                    <SelectItem value="november">November</SelectItem>
                    <SelectItem value="december">December</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="awsCustomerSinceYear"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                AWS Customer Since Year
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="font-stolzl  p-6 rounded-full text-gray-500">
                    <SelectValue placeholder="Select Year" />{" "}
                    <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>{yearsFromNow()}</SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="projectStatus"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                Project Status
              </FormLabel>
              <FormItem className="mx-1">
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="p-6 font-stolzl rounded-full  text-gray-500">
                      <SelectValue placeholder="Project Status" />{" "}
                      <ChevronDown className="font-bold text-black ml-2 h-6 w-6 shrink-0 " />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="ongoing">Ongoing</SelectItem>
                      <SelectItem value="completed">Completed</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="monthlyBillInUsd"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                Monthly Aws Bill In Usd
              </FormLabel>
              <FormControl>
                <Input
                  className=" rounded-full p-6"
                  placeholder="Monthly Aws Bill In Usd"
                  {...field}
                  onChange={(e) =>
                    form.setValue(
                      "monthlyBillInUsd",
                      e.target.value.replace(new RegExp("[^0-9]"), "")
                    )
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <ColorCard
          variant="primary"
          className=" col-span-2 mb-0 "
          message="Upload a screenshot of the AWS Billing Dashboard or the last month invoice for the project below. The screenshot must show the AWS Account ID. Providing this information may expedite the approval process!"
        />
        <UploadInvoiceImages form={form} />
        <FormField
          control={form.control}
          name="additionalDocumentPath"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-stolzl-r  text-md">
                Additional Document Path
              </FormLabel>
              <FormControl>
                <Input
                  className="p-6  rounded-full"
                  placeholder="Additional Document Path"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
});

export default function AddProjectDialog({ title, className }: any) {

  const [searchParams, setSearchParams] = useSearchParams();
  const formref = useRef();
  const [submitting, setSubmitting] = useState(false);
  
  function handleOpen(action?: string) {
    if (action === "close") {
      setSearchParams({ m: "" });
      return false;
    }
    return searchParams.get("m") === "addproject" ? true : false;
  }

  function handleSubmit() {
    setSubmitting(true);
    //@ts-ignore
    formref.current.onSubmit();
    setSubmitting(false);
  }

  return (
    <AlertDialog open={searchParams.get("m") === "addproject"}>
      <AlertDialogTrigger asChild>
        <Button
          className={cn("rounded-full px-6", className)}
          onClick={() => setSearchParams({ m: "addproject" })}
        >
          {title}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className=" lg:max-w-4xl md:max-w-2xl sm:max-w-xl max-w-lg ">
        <AlertDialogHeader className="flex flex-row  justify-between  items-center">
          <AlertDialogTitle className="">
            Add a Launched AWS Customer
          </AlertDialogTitle>
          <Button variant={"ghost"} onClick={() => handleOpen("close")}>
            <X />
          </Button>
        </AlertDialogHeader>

        <div className="scrollable-content h-[65vh] no-scollbar  overflow-scroll  ">
          <AlertDialogDescription className="">
            <ColorCard
              className="font-stolzl"
              variant="primary"
              message={
                'Once you submit all the information, your project will be submitted for approval. After the approval you will be able to see the approved projects and the reward in the "Cash Unlocked" section.'
              }
            />
          </AlertDialogDescription>

          <AddProjectForm ref={formref} handleClose={handleOpen} />
        </div>
        <AlertDialogFooter className="flex gap-6">
          <AlertDialogCancel
            className="p-6 px-12 border-primary text-primary rounded-full"
            onClick={() => handleOpen("close")}
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            className="p-6 px-12 rounded-full"
            disabled={submitting}
            onClick={handleSubmit}
          >
            {submitting ? <Spinner /> : "Submit"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
