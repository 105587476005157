import { toast } from "sonner";
import { LOCAL_STORAGE_KEYS } from "src/components/Types";

let tokenName = "CLOUDZENIA_APN_PARTNERSHIP_TOKEN";

let BASE_PATH: string = "";
if (process.env.REACT_APP_NODE_ENV === "development") {
  console.log(process.env.REACT_APP_NODE_ENV)
  //@ts-ignore
  BASE_PATH = process.env.REACT_APP_DEV_URL;
} else {
  //@ts-ignore
  BASE_PATH = process.env.REACT_APP_PROD_URL;
}

const unSecuredRoutes = [
  "/authentication/send-otp",
  "/authentication/verify-otp",
  "/authentication/login",
  "/authentication/signup",
];

export function removeLocalData() {

  window.localStorage.removeItem(
    LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
  );
  window.localStorage.removeItem(
    LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP);
}

function getLocalData(tokenName: LOCAL_STORAGE_KEYS) {
  const getItem = localStorage.getItem(tokenName);


  if (getItem) {
    const data = JSON.parse(getItem);
    return data.token;
  } else {
    console.log(tokenName);
  }
}

export const apiGet = async (url: string): Promise<any> => {
  let Authorization = unSecuredRoutes.includes(url)
    ? ""
    : `Bearer ${getLocalData(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      )}`;

  return new Promise((resolve, reject) => {
    fetch(`${BASE_PATH}${url}`, {
      method: "GET",
      headers: {
        Authorization,
        "Content-Type": "application/json",
      },
    })
      .then((res: any) => {

        if (res.ok) {
          resolve(res.json());
        } else {
          removeLocalData();
        }
        if (res.status === 401) {
          
          toast.error("Please Login again!!");
        }
        if (res.status === 403) {
          reject()
        }
      })
      .catch((error) => {
        console.log("An Error occured ", error);
        reject(error);
      });
  });
};

export const apiPost = async (url: string, data: any): Promise<any> => {
  let Authorization = unSecuredRoutes.includes(url)
    ? ""
    : `Bearer ${getLocalData(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      )}`;
  return new Promise((resolve, reject) => {
    fetch(`${BASE_PATH}${url}`, {
      method: "POST",
      headers: {
        Authorization,
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res: any) => {
        if (res.ok) {
          resolve(res.json());
        }
        if(res.status===400) { 
          reject(new Error())
        }
        if (res.status === 401) {
          toast.error("Please Login again!!");
        }
        if (res.status === 403) {
          reject()
        }
        if (res.status === 500) toast.error("Server Error, Try again later!");
      })
      .catch((error) => {
        console.log("An Error occured ", error);
        reject(error);
      });
  });
};

export const apiPut = async (url: string, data: any): Promise<any> => {
  let Authorization = unSecuredRoutes.includes(url)
    ? ""
    : `Bearer ${getLocalData(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      )}`;

  return new Promise((resolve, reject) => {
    fetch(`${BASE_PATH}${url}`, {
      method: "PUT",
      headers: {
        Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res: any) => {
        if (res.ok) {

            resolve(res.json());
          }
        if (res.status === 401) {
          
          toast.error("Please Login again!!");
        }
        if (res.status === 403) {
          reject()
        }
        if (res.status === 500) toast.error("Server Error, Try again later!");
      })
      .catch((error) => {
        console.log("An Error occured ", error);
        reject(error);
      });
  });
};

export const apiDelete = async (url: string): Promise<any> => {
  let Authorization = unSecuredRoutes.includes(url)
    ? ""
    : `Bearer ${getLocalData(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      )}`;
  return new Promise((resolve, reject) => {
    fetch(`${BASE_PATH}${url}`, {
      method: "DELETE",
      headers: {
        Authorization,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res: any) => {
        if (res.ok) {
          resolve(res.json());
        } else {
          removeLocalData();
        }
        if (res.status === 401) {
          toast.error("Please Login again!!");
        }
        if (res.status === 403) {
          throw Error()
        }
        if (res.status === 500) toast.error("Server Error, Try again later!");
      })
      .catch((error) => {
        console.log("An Error occured ", error);
        reject(error);
      });
  });
};

export const apiPostImage = async (url: string, data: any): Promise<any> => {
  let Authorization = unSecuredRoutes.includes(url)
    ? ""
    : `Bearer ${getLocalData(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      )}`;
  return new Promise((resolve, reject) => {
    fetch(`${BASE_PATH}${url}`, {
      method: "POST",
      body: data,
      headers: {
        Authorization,
      },
    })
      .then((res: Response) => {
        if (res.ok) {
          resolve(res.text());
        } else {
          removeLocalData();
        }

        if (res.status === 401) {
          
          toast.error("Please Login again!!");
        }
        if (res.status === 500) toast.error("Server Error, Try again later!");
      })
      .catch((error) => {
        toast.error("Something went Wrong when Uploading file");
        console.log("An Error occured ", error);
      });
  });
};
