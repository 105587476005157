import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { USERTYPE } from "src/components/Types";
import Div from "src/components/typography/div";
import useDevice from "src/lib/hooks/useDevice";
import { useAuth } from "src/lib/useAuth";
import Sidebar from "src/views/sidebar";
import Layout from "./layout";

function Dashboard() {
  const auth = useAuth();
  const { user } = auth;
  const device = useDevice();
  const nav = useNavigate();
  
  useEffect(() => {
    if (!user) return nav("/login");
    user.userType === USERTYPE.SUPER_ADMIN && nav("/dashboard/admin/projects");
    !user.userInvited && nav("/waiting");
    user.userType === USERTYPE.USER && nav("/dashboard/projects");
  }, [user]);

  return (
    <div className="flex ">
      {device !== "mobile" && (
        <Div className="bg-black m-0 p-0">
          <Sidebar user={user} />
        </Div>
      )}
      <Layout user={user} device={device}>
        <Outlet />
      </Layout>
    </div>
  );
}

export default Dashboard;
