export interface UpdateProjectStatus {
  projectKey: number;
  emailId: string;
  arpStatus: "review" | "approved" | "rejected";
  comments: string;
  reviewedDate: string;
  approvedDate: string;
  rewardAmount: number;
  cashUnblocked: string;
}

export interface RewardClaimDto {
  rewardKey: number;
  projectKey: number;
  emailId: string;
  quarter: string;
  totalAmount: number;
  comments: string;
  rewardStatus: RewardStatusType
  flexiField1: string;
  flexiField2: string;
  flexiField3: string;
  createdBy: string;
  createdDate: string;
  status: string;
}
export interface CreateRewardClaimDto {
  emailId: string;
  quarter: string;
  totalAmount: number;
  comments: string;
  rewardKey?: number;
  rewardStatus: RewardStatusType;
}
export type RewardStatusType =
  | "created"
  | "businessApproved"
  | "financeApproved"
  | "completed"
  | string;

export interface claimUpdateDto {
  emailId: string;
  quarter: string;
  totalAmount: number;
  comments: string;
  rewardStatus: RewardStatusType;
  rewardKey?: number;
}

export interface RewardClaim {
  rewardKey: number;
  projectKey: number;
  emailId: string;
  quarter: string;
  totalAmount: number;
  comments: null;
  rewardStatus: RewardStatusType;
  flexiField1: null;
  flexiField2: null;
  flexiField3: null;
  createdBy: string;
  createdDate: string;
  status: string;
}

export interface QYFilters {
  emailId: string;
  quarter: string;
  projectYear: string;
}
export interface GetAll {
  projectSummary: ProjectSummary;
  projectResponseList: ProjectResponseDto[];
}

export interface CustomTypographyProps {
  className?: string;
  children: React.ReactNode;
}

export type ProjectSummary = {
  quarter: string;
  totalProjectCount: number;
  arpReviewProjectCount: number;
  approvedProjectCount: number;
  rejectedProjectCount: number;
};
export interface createUserDto {
  emailId: string;
  password: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  state: string;
  // roles: string;
  pinCode: string;
  country: string;
  // userType: string;
  userCategory: string;
}
export enum USERTYPE {
  SUPER_ADMIN = "superAdmin",
  USER = "user",
  ADMIN = "admin",
}

export interface IUser {
  status: string;
  addressLine1: string;
  token?: string;
  addressLine2: string;
  country: string;
  emailId: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  pinCode: string;
  password: string;
  roles: string;
  state: string;
  userCategory: string;
  userId: string;
  userType: USERTYPE;
  userInvited: boolean;
}
export interface ImageDto {
  emailId: string;
  awsAccountId: string;
  file: [string];
}

export interface ProjectResponseDto {
  comments: string;
  emailId: string;
  projectKey: number;
  projectYear: string;
  arpStatus: "review" | "approved" | "rejected";
  customerCompanyName: string;
  customerWebsite: string;
  industrialVertical: string;
  country: string;
  postalCode: string;
  projectTitle: string;
  aboutBusiness: string;
  custBusinessProblem: string;
  implementedSolution: string;
  awsAccountId: string;
  awsInventories: string;
  customerFindingSource: string;
  awsCustomerSinceYear: string;
  awsCustomerSinceMonth: string;
  projectStatus: string;
  monthlyBillInUsd: string;
  cashUnblocked: string;
  arpEligible: string;

  rewardStatus: RewardStatusType;
  documentList: [
    {
      documentKey: number;
      emailId: string;
      s3Url: string;
    }
  ];
  flexiField1: string;
  flexiField2: string;
  flexiField3: string;
  createdBy: string;
  createdDate: string;
  status: string;
  reviewedDate: string;
  approvedDate: string;
  rewardAmount: number;
}

export interface ProjectDto {
  emailId: string;
  customerCompanyName: string;
  customerCompanyWebsiteUrl: string;
  industryVertical: string;
  country: string;
  postalCode: string;
  projectTitle: string;
  aboutBusiness: string;
  customerBusinessProblem: string;
  implementedSolution: string;
  awsAccountId: string;
  awsServiceInventories: [string];
  customerFindingSource: string;
  awsCustomerSinceMonth: string;
  awsCustomerSinceYear: string;
  projectStatus: string;
  monthlyAwsBillInUsd: number;
  invoiceImage1Path: string;
  invoiceImage2Path: string;
  additionalDocumentPath: string;
}

export enum LOCAL_STORAGE_KEYS {
  CLOUDZENIA_APN_PARTNERSHIP = "CLOUDZENIA_APN_PARTNERSHIP",
  CLOUDZENIA_APN_PARTNERSHIP_TOKEN = "CLOUDZENIA_APN_PARTNERSHIP_TOKEN",
}

export enum LoginType {
  USER = "user",
  SUPERUSER = "superUser",
}
export interface InoviceDto {
  emailId: string;
  documentType: string;
  file: [string];
}

export interface InoviceResponseDto {
  emailId: string;
  documentType: string;
  documentName: string;
  referenceNo: string;
  url: string;
}

export interface BankingDetailsDto {
  emailId: string;
  accountHolderFullName: string;
  accountType: string;
  bankName: string;
  ifsc: string;
  accountNumber: string;
}

export interface BankingDetailsReponseDto {
  ifscCode: string;
  emailId: string;
  accountHolderFullName: string;
  accountType: string;
  bankName: string;
  ifsc: string;
  accountNumber: string;
}
