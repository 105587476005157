import Div from "src/components/typography/div";
import CustomTable from "../dashboard/cash-unlocked/CustomTable";

function UserProjects({projects}:any) {
  
  return (
    <Div>
        <CustomTable projects={projects} />
    </Div>
  );
}

export default UserProjects;
