import * as React from 'react'
import { cn } from "../../lib/utils"

import { Check, ChevronDown, X } from "lucide-react"
import { Badge } from "./badge"
import { Button } from "./button"
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandList
} from "./command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "./popover"
import { CommandItem } from 'cmdk'
import { ScrollArea } from './scroll-area'


export type OptionType = {
    label: string;
    value: string;
}

interface MultiSelectProps {
    options: OptionType[];
    selected: string[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    className?: string;
}

function MultiSelect({ options, selected, onChange, className, ...props }: MultiSelectProps) {

    const [open, setOpen] = React.useState(false)

    const handleUnselect = (item: string) => {
        onChange(selected.filter((i) => i !== item))
    }
    return (
        <Popover modal={true} open={open} onOpenChange={setOpen} {...props}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`w-full justify-between h-max py-[.85rem] rounded-[26px]
                    `}
                    onClick={() => setOpen(!open)}
                >
                    <div className="flex gap-1 flex-wrap">
                        {selected.length > 0 ? selected.map((item) => (
                            <Badge
                                variant="secondary"
                                key={item}
                                className="mr-1 mb-1"
                                onClick={() => handleUnselect(item)}
                            >
                                {item}
                                <button
                                    className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleUnselect(item);
                                        }
                                    }}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => handleUnselect(item)}
                                >
                                    <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                                </button>
                            </Badge>
                        )) : <p className='text-gray-400 font-stolzl'>AWS services</p>}
                    </div>
                    <ChevronDown className="" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className='p-0 w-[400px]' >
                <Command className={className}>
                    <CommandInput placeholder="Search ..." />
                    <CommandGroup className=''>
                        <CommandList className='max-h-full'>
                            <ScrollArea className="h-72">

                                {options.map((option) => (
                                    <CommandItem
                                        className='relative pl-8 p-1'
                                        // variant={'ghost'}
                                        key={option.label}
                                        value={option.label}
                                        onSelect={() => {
                                            onChange(
                                                selected.includes(option.label)
                                                    ? selected.filter((item) => item !== option.label)
                                                    : [...selected, option.label]
                                            )
                                            setOpen(true)
                                        }}>
                                        <Check
                                            className={cn(
                                                "mr-2  h-4 w-4 left-2 absolute",
                                                selected.includes(option.label) ?
                                                    "opacity-100" : "opacity-0"
                                            )}
                                        />  {option.label}
                                    </CommandItem>))}
                            </ScrollArea>
                        </CommandList>

                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export { MultiSelect }
