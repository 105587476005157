import { ReactNode } from 'react'
import { cn } from 'src/lib/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card'


interface ColorCardProps {
    message?: string, title?: string, variant: 'error' | 'success' | 'primary' | 'warning', className?: string, children?: ReactNode
}


function ColorCard({ message, title, variant, className, children }: ColorCardProps) {
    const bgcolors = {
        'error': 'bg-red-100/70',
        'success': 'bg-[#EAF9EE]',
        'primary': 'bg-[#E9EFFF]',
        'warning': 'bg-yellow-500/10'
    }
    const textcolors = {
        'error': 'text-red-700',
        'success': 'text-[#0FA033]',
        'primary': 'text-[#154CD8]',
        'warning': 'text-yellow-500'
    }

    return (
        <Card className={cn("mb-6 border-none overflow-hidden", className)}>

            {title &&
                <CardHeader>
                    <CardTitle>{title}</CardTitle>
                </CardHeader>}

            {message && <CardContent className={` p-4 over ${bgcolors[variant]} border-none shadow-none `}>
                <CardDescription >
                    <p className={` ${textcolors[variant]}  text-[16px] font-stolzl leading-7 `} >{message}</p>
                    {children}
                </CardDescription>
            </CardContent>}
        </Card>

    )
}

export default ColorCard