import Div from "src/components/typography/div";
import H4 from "src/components/typography/h4";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectQuarter } from "src/pages/my-projects/my-projects-tab";
import { useProjectStore } from "src/store/projectStore";
import { ProjectsTable } from "./projectstable";

function Projects() {
  
  const {  
    year,
    monthFilter,
    setQuarterYear,
    quarter,
  } = useProjectStore();

  const [searchParams, setSearchParams ]= useSearchParams()
  useEffect(()=>{
    if(!quarter)
      monthFilter()
  },[])

  return (
    <Div>
      <div className="flex justify-between mb-6 ">
        <H4>All Projects</H4>
       {searchParams.get('projectKey')? '' : 
       <SelectQuarter year={year} quarter={quarter} setQuarterYear={setQuarterYear}/>}
      </div>
      <ProjectsTable  />
    </Div>
  );
}

export default Projects;
