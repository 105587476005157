import { zodResolver } from "@hookform/resolvers/zod"
import { SetStateAction, useState } from "react"
import { useForm } from "react-hook-form"

import { toast } from "sonner"
import { Button } from "src/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form"
import { Input } from "src/components/ui/input"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "src/components/ui/input-otp"
import { AuthValuesType } from "src/lib/auth"
import { z } from "zod"


const FormSchema = z.object({
    pin: z.string().min(6, {
        message: "Your one-time password must be 6 characters.",
    }),
})
interface OTPProps {
    auth: AuthValuesType,
    setEmail: React.Dispatch<SetStateAction<string | null>>
    email: string | null
}
function OtpC({ auth, setEmail, email }: OTPProps) {
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            pin: "",
        },
    })

    function onSubmit(data: z.infer<typeof FormSchema>) {

        auth.verifyOtp({
            emailId: email,
            otp: data.pin
        })

    }

    return (
        <div className=''>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="  text-center items-center mx-auto space-y-6  pb-6">
                    <FormField
                        control={form.control}
                        name="pin"
                        render={({ field }) => (
                            <FormItem className='text-left'>
                                <FormLabel className=' text-gray-500'>OTP</FormLabel>

                                <FormControl className=''>
                                    <InputOTP maxLength={6}  {...field}  onKeyDown={e => {
                                        if(e.code === 'Enter') { 
                                            e.preventDefault()
                                          onSubmit(form.getValues())
                                        }
                                    }}>
                                        <InputOTPGroup className='gap-2 md:gap-4  w-full  flex justify-center'>
                                            <InputOTPSlot autoFocus index={0} className='rounded-full border-gray-400' />
                                            <InputOTPSlot index={1} className='rounded-full border-gray-400' />
                                            <InputOTPSlot index={2} className='rounded-full border-gray-400' />
                                            <InputOTPSlot index={3} className='rounded-full border-gray-400' />
                                            <InputOTPSlot index={4} className='rounded-full border-gray-400' />
                                            <InputOTPSlot index={5} className='rounded-full border-gray-400' />
                                        </InputOTPGroup>
                                    </InputOTP>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <p className='text-sm text-left font-stolzl text-gray-400'>Didn't recieve an OTP?
                        <span className="text-primary  font-stolzl-r"> <button onClick={() => setEmail(null)}>Resend OTP</button>
                        </span></p>
                    <div className=' w-full flex gap-4 '>
                        <Button className='w-full font-stolzl h-12 rounded-full' type="submit">Submit</Button>
                    </div>

                </form>
            </Form>
        </div>
    )
}

const formSchema = z.object({
    emailId: z.string().min(1, 'Please Enter Your Email').email().toLowerCase()
})


interface LoginWithOtpProps {
    auth: AuthValuesType,
}

export function LoginWithOtp(props: LoginWithOtpProps) {

    const { auth } = props
    const [email, setEmail] = useState<string | null>(null)

    const form = useForm<z.infer<typeof formSchema>>({
        defaultValues: { emailId: '' },
        resolver: zodResolver(formSchema),
    })
    function handleSendOtp(values: z.infer<typeof formSchema>) {
        try {
            auth.sendOtp({ emailId: values.emailId.toLowerCase() })
            setEmail(values.emailId)
        } catch (error) {
            toast.warning('Sorry, try again later!!')
        }
    }
    return (

        <>{email ?
            <OtpC email={email} auth={auth} setEmail={setEmail} /> :
            <div data-status={email} className='w-full  text-center space-y-2 data-[status=false]:hidden'>
                <Form {...form} >
                    <form className='space-y-4 ' onSubmit={form.handleSubmit(handleSendOtp)}>
                        <FormField
                            control={form.control}
                            name='emailId'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <div className='flex gap-4  items-center justify-between'>
                                        <FormLabel className=''>Email</FormLabel>
                                        <FormMessage /></div>
                                    <FormControl>
                                        <Input className='rounded-full p-6 text-gray-600' {...field} placeholder='Enter your email' />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <Button className='w-full rounded-full' type='submit'>Send OTP</Button>
                    </form>
                </Form>
            
            </div>}</>)
}
