import { ArrowLeftCircle } from "lucide-react";
import moment from "moment";
import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ProjectResponseDto } from "src/components/Types";
import Div from "src/components/typography/div";
import H4 from "src/components/typography/h4";
import Lead from "src/components/typography/lead";
import Table from "src/components/typography/table";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "src/components/ui/card";
import { Label } from "src/components/ui/label";
import Spinner from "src/components/ui/spinner";
import { TableCell, TableRow } from "src/components/ui/table";
import { useProjectStore } from "src/store/projectStore";
import { ProjectStatusC } from "src/views/myprojects/MyProjectsTable";
interface BasicInfoProps {
  projectDetails: ProjectResponseDto;
}
export function getDateWithMoment(date: string | null) {
  if (date) return moment(date).format("DD/MM/YY");
  return "-";
}
function BasicInfoCard({ projectDetails }: BasicInfoProps) {
  return (
    <>
      <Card className="border-none shadow-none bg-[#E9EFFF]">
        <CardContent className="p-6 ">
          <CardDescription className="flex justify-between ">
            <div className=" ">
              <Label className="text-primary ">Freelancer</Label>
              <H4 className="m-0 text-black">
                {projectDetails.customerCompanyName}
              </H4>
            </div>
            <div>
              <Label className="text-primary ">Deal Manager</Label>
              <Lead className="text-lg">{projectDetails.createdBy}</Lead>
            </div>
          </CardDescription>
        </CardContent>
      </Card>
      <Card className="shadow-none font-stolzl-r">
        <CardContent className="p-6 ">
          <H4 className="pb-2 font-stolzl-r ">Basic Details</H4>
          <Lead className="text-black text-md">
            {" "}
            Project : {projectDetails.projectTitle}
          </Lead>
          <CardDescription className="">
            <Table>
              <TableRow>
                <TableCell className="w-[150px]">
                  <H4 className="text-sm font-normal mt-2">Submit Date:</H4>
                </TableCell>
                <TableCell className="w-[300px]">
                  <H4 className="text-sm font-normal mt-2">
                    {moment(projectDetails.createdDate).format("DD/MM/YY") ??
                      "DD-MM-YYYY"}{" "}
                  </H4>
                </TableCell>
                <TableCell className="w-[150px]">
                  <p className="pt-3">Country: </p>
                </TableCell>
                <TableCell>
                  <p className="pt-3">{projectDetails.country}</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <p className="pt-3">Company: </p>
                </TableCell>
                <TableCell>
                  <p className="pt-3">{projectDetails.customerCompanyName}</p>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">AWS Account Id:</H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">
                    {projectDetails.awsAccountId}
                  </H4>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">Cash Unlocked:</H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2 ">
                    {projectDetails.rewardAmount ?? "Pending"}
                  </H4>
                </TableCell>

                <TableCell>
                  <H4 className="text-sm font-normal mt-2">
                    Customer Website:
                  </H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2 text-primary">
                    <a href={projectDetails.customerWebsite}>
                      {projectDetails.customerWebsite}
                    </a>
                  </H4>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">Postal Code:</H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">
                    {projectDetails.postalCode}
                  </H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">Reviewed On:</H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2 ">
                    {getDateWithMoment(projectDetails?.reviewedDate)}
                  </H4>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">CRP Eligible:</H4>
                </TableCell>
                <TableCell>
                  <H4 className="text-sm font-normal mt-2">
                    <ProjectStatusC status={projectDetails.arpStatus} />
                  </H4>
                </TableCell>
              </TableRow>
            </Table>

            {projectDetails.arpStatus === "rejected" && (
              <div className="py-4 px-2 flex text-center items-center">
                <Label>Rejection Reason :</Label>
                <p>{projectDetails.comments}</p>
              </div>
            )}
          </CardDescription>
        </CardContent>
      </Card>
    </>
  );
}

function ProjectImagesCard({ projectDetails }: BasicInfoProps) {
  return (
    <Card className="bg-[#E9EFFF] shadow-none">
      <CardHeader className="pb-2"></CardHeader>
      <CardContent>
        {projectDetails?.documentList?.length <= 0 ? (
          <p>No image uploads</p>
        ) : (
          <>
            {" "}
            <p>Here are the document files uploaded</p>
            {projectDetails?.documentList?.map((item, index) => (
              <Link className="text-primary" to={item.s3Url}>
                <p className=" hover:underline">
                  View File {index + 1}. {item.s3Url.split("/").pop()} <br />
                </p>
              </Link>
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
}
function ParterInfoCard({ projectDetails }: BasicInfoProps) {
  return (
    <Card className="shadow-none">
      <CardContent className="pt-6  ">
        <Lead className="text-lg text-black"> Partner Info</Lead>

        <CardDescription className="">
          <Table>
            <TableRow>
              <TableCell className="w-[150px]">
                <p className="pt-3">Name: </p>
              </TableCell>
              <TableCell className="w-[300px]">
                <p className="pt-3">{projectDetails.emailId}</p>
              </TableCell>
              <TableCell className="w-[150px]">
                <p className="pt-3">Country: </p>
              </TableCell>
              <TableCell>
                <p className="pt-3">{projectDetails.country}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <p className="pt-3">Company: </p>
              </TableCell>
              <TableCell>
                <p className="pt-3">{projectDetails.customerCompanyName}</p>
              </TableCell>
            </TableRow>
          </Table>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

function ProjectDetails() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getProjectById } = useProjectStore();
  useEffect(() => {
    getProjectById(searchParams.get("projectKey") ?? "id");
  }, []);
  return (
    <Div className="animate-in pt-0 slide-in-from-right-6 ">
      <ProjectDet navigate={navigate} />
    </Div>
  );
}

export default ProjectDetails;

function ProjectDet({ navigate }: any) {
  const { project } = useProjectStore();
  if (!project) return <Spinner />;
  return (
    <>
      <div className="flex gap-2 mb-2">
        <ArrowLeftCircle onClick={() => navigate(-1)} />
        <div>
          <Lead>{"Project Details"}</Lead>
          <Lead className="font-light text-md">
            <Label>{"Project ID: "} </Label>
            {project.projectKey}
          </Lead>
        </div>
      </div>
      <Card className="p-6 border-none shadow-none space-y-6">
        <BasicInfoCard projectDetails={project} />
        <ParterInfoCard projectDetails={project} />
        <Card>
          {" "}
          <CardContent className="pt-6 space-y-3 col-span-4">
            <p>Problem Statement</p>
            <p className=" text-gray-400 ">{project.custBusinessProblem}</p>
            <p>Solution Provided</p>
            <p className=" text-gray-400 ">{project.implementedSolution}</p>

            <p>Aws Services</p>
            <p className=" text-gray-400 ">{project.awsInventories}</p>
          </CardContent>
        </Card>
        <ProjectImagesCard projectDetails={project} />
      </Card>
    </>
  );
}
