import { useEffect } from "react";
import { ProjectResponseDto } from "src/components/Types";
import CustomDropdown from "src/components/custom/dropdown";
import { Icons } from "src/components/custom/icons";
import Div from "src/components/typography/div";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/components/ui/select";
import Spinner from "src/components/ui/spinner";
import { useAuth } from "src/lib/useAuth";
import { useProjectStore } from "src/store/projectStore";
import { CustomCard } from "../../my-projects/my-projects-tab";
import ProjectsTable from "./ProjectsTable";

function SelectOptions({ quarters }: any) {
  return (
    <>
      {quarters.map((item: any) => (
        <SelectItem key={item.value} value={item.value}>
          {item.value}
        </SelectItem>
      ))}
    </>
  );
}
function SelectApprovalQuarter({ device }: any) {
  const { year, quarter, getProjectsWithFilters, setQuarterYear } =
    useProjectStore();
  const { user } = useAuth();
  const quarters = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ];
  const years = [
    { value: "2024", label: 2024 },
    { value: "2025", label: 2025 },
  ];
  useEffect(() => {
    if (!quarter && !year) return;
    //@ts-ignore
    getProjectsWithFilters({emailId: user?.emailId,quarter,projectYear: year,
    });
  }, [quarter, year]);
  if (!quarter || !year) return <div> Something bad </div>;
  return (
    <div className={`flex ${device === "mobile" ? "w-full" : "w-max"}`}>
      <Select
        defaultValue={quarter ?? "Q1"}
        onValueChange={(e) => setQuarterYear(e, year)}
      >
        <SelectTrigger className="  focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue defaultValue={quarter} placeholder="Quarter" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={quarters} />
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        defaultValue={"2024"}
        onValueChange={(e) => setQuarterYear(quarter, e)}
      >
        <SelectTrigger className=" focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue placeholder="Year" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={years} />
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
function ApprovedCards({
  quarter,
  year,
  projects,
}: {
  quarter: string | null;
  year: string | null;
  projects: ProjectResponseDto[];
}) {
  const totalReward = projects.reduce(
    (acc, item) => acc + item.rewardAmount,
    0
  );
  const monthStamps = {
    Q1: "01 Apr - 15 Apr",
    Q2: "01 July - 15 July",
    Q3: "01 Oct - 15 Oct",
    Q4: "01 Jan - 15 Jan",
  };
  //@ts-ignore
  const value = monthStamps[quarter];
  return (
    <Div className="pt-0 grid md:grid-cols-2 gap-6">
      <CustomCard
        title="Approved Reward"
        icon={<Icons.ApprovedRewards className="w-full h-full" />}
        stat={totalReward}
      />
      <CustomCard
        title="To Be Paid Between"
        icon={<Icons.Calender className="w-full h-full p-1" />}
        stat={value}
      />
    </Div>
  );
}

function CashUnlocked({ device }: any) {
  const { projects, quarter, year } = useProjectStore();
  if (!projects) return <Spinner />;
  const cashunlockedprojects = projects.filter(
    (proj: ProjectResponseDto) => proj.cashUnblocked === "Y"
  );
  return (
    <Div className="p-0 mt-0">
      <div className="w-full p-2 pt-0  flex justify-end">
        <SelectApprovalQuarter device={device} />
      </div>
      <ApprovedCards
        quarter={quarter}
        year={year}
        projects={cashunlockedprojects}
      />

      {cashunlockedprojects.length <= 0 ? (
        <Div>
          <Card className="shadow-none border-none">
            <CardHeader>
              <CardTitle></CardTitle>
            </CardHeader>
            <CardContent className="space-y-4 ">
              <img src="/images/add.png" className="mx-auto" alt="" />

              <h2 className="text-3xl text-center">
                Looks like you have no projects approved yet
              </h2>

              <CardDescription className="text-center leading-5">
                Once You submit your projects wait <br /> atleast 3-5 business
                days for project approval
              </CardDescription>
            </CardContent>
            <CardFooter />
          </Card>
        </Div>
      ) : (
        <ProjectsTable projects={cashunlockedprojects} />
      )}
    </Div>
  );
}

export default CashUnlocked;
