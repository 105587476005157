import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from 'src/components/ui/spinner'

function PageNotFoundError() {
    const path = useLocation().pathname
    const navigate = useNavigate()
    useEffect(()=>{
        navigate(-1)
    },[])
    return ( <Spinner/>)
}

export default PageNotFoundError