import { cn } from "src/lib/utils";
import { CustomTypographyProps } from "../Types";

function H4({ children, className }: CustomTypographyProps) {
    return (
        <h4 className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)} >{children}</h4>

    )
}

export default H4