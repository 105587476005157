import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { toast } from "sonner";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { AuthValuesType } from "src/lib/auth";
import { z } from "zod";

const formSchema = z.object({
  emailId: z.string().min(1, "Please Enter Your Email").email().toLowerCase(),
  password: z.string().min(1, "Please Enter Password").toLowerCase(),
});

interface LoginWithPasswordProps {
  auth: AuthValuesType;

}

function handleLogin(auth:AuthValuesType,values: z.infer<typeof formSchema>) {

    try {
      auth.login({ emailId: values.emailId.toLowerCase(), password: values.password  });
    } catch (error) {
      toast.warning("Sorry, try again later!!");
    }
  }

export function LoginWithPassword(props: LoginWithPasswordProps) {
  const { auth} = props;
  
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: { emailId: "", password: "" },
    resolver: zodResolver(formSchema),
  });
  
  return <div
      className="w-full  text-center space-y-2"
    >
      <Form {...form}>
        <form
          className="space-y-4 "
          onSubmit={form.handleSubmit(e => handleLogin(auth, e ))}
        >
          <FormField
            control={form.control}
            name="emailId"
            render={({ field }) => (
              <FormItem className="">
                <div className="flex gap-4  items-center justify-between">
                  <FormLabel className="">Email</FormLabel>
                  <FormMessage />
                </div>
                <FormControl>
                  <Input
                    className="rounded-full p-6 text-gray-600"
                    {...field}
                    placeholder="Enter your email"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="">
                <div className="flex gap-4  items-center justify-between">
                  <FormLabel className="">Password</FormLabel>
                  <FormMessage />
                </div>
                <FormControl>
                  <Input
                    className="rounded-full p-6 text-gray-600"
                    {...field}
                    placeholder="Enter your password"
                    type="password"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button className="w-full rounded-full" type="submit">
          Login</Button>
        </form>
      </Form>
    
    </div>
}
