import { zodResolver } from "@hookform/resolvers/zod"
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu"
import { ArrowLeftCircleIcon } from "lucide-react"
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import Div from 'src/components/typography/div'
import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'src/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'src/components/ui/form'
import { Input } from 'src/components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select"
import { Textarea } from 'src/components/ui/textarea'
import { useAuth } from "src/lib/useAuth"
import { z } from 'zod'
const formSchema = z.object({
    emailId: z.string().email("Enter a valid EmailId").toLowerCase(),
    password: z.string().min(8, "Atleast use 8 characters for your password!!"),
    mobileNumber: z.string(),
    firstName: z.string().min(3).max(25, 'Names should be under 25 characters'),
    lastName: z.string().min(3).max(25, 'Names should be under 25 characters'),
    addressLine1: z.string().min(3).max(220, "Please Address length under 220 characters"),
    addressLine2: z.string().min(3).max(220, "Please Address length under 220 characters"),
    state: z.string().min(3),
    roles: z.string().min(4),
    pinCode: z.string().min(4),
    country: z.string().min(4),
    // userType: z.string().min(4),
    userCategory: z.string().min(2),
})
type Checked = DropdownMenuCheckboxItemProps["checked"]
function Register() {
    const auth = useAuth()
    const navigate = useNavigate()
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            emailId: auth.user?.emailId ?? '',
            password: "",
            mobileNumber: "",
            firstName: "",
            lastName: "",
            addressLine1: "",
            addressLine2: "",
            state: "",
            roles: "",
            pinCode: "",
            country: "",
            // userType: "",
            userCategory: ""
        },
    })

    function onSubmit(values: z.infer<typeof formSchema>) {
        auth.register(values)
    }

    return (
        <Div className="relative overflow-hidden">
            <img src="/images/loginbackground.jpeg" className=' scale-105 w-full  object-cover h-screen  absolute' alt="" />
            <Div className="animate-in bg-transparent slide-in-from-right-6 relative " >
                <Card className="max-w-[750px] max-h-[90vh] mx-auto  space-y-2">
                    <CardHeader className="pb-0"><CardTitle>
                        <ArrowLeftCircleIcon onClick={() => navigate(-1)} />
                        Register Form</CardTitle></CardHeader>
                    <CardContent   >
                        <CardDescription >
                            <Form  {...form}>
                                <form
                                    onSubmit={form.handleSubmit(onSubmit)} >
                                    <Div className="grid max-h-[70vh] overflow-auto grid-cols-2 gap-6 bg-white">


                                        <FormField
                                            control={form.control}
                                            name="emailId"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Email Id
                                                    </FormLabel>
                                                    <FormControl >
                                                        <Input placeholder="Email Id" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Password
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Password" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />

                                        <FormField
                                            control={form.control}
                                            name="firstName"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        First Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="first name " {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />

                                        <FormField
                                            control={form.control}
                                            name="lastName"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Last Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Last Name" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField
                                            control={form.control}
                                            name="mobileNumber"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Mobile Number
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Mobile Number" {...field} type="number" />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField

                                            control={form.control}
                                            name="addressLine1"
                                            render={({ field }) => (
                                                <FormItem className="col-span-2">
                                                    <FormLabel>
                                                        Address Line 1
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Textarea placeholder="Address 1" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />

                                        <FormField
                                            control={form.control}
                                            name="addressLine2"
                                            render={({ field }) => (
                                                <FormItem className="col-span-2">
                                                    <FormLabel>
                                                        Address Line 2
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Textarea placeholder="Addres Line 2 " {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />

                                        <FormField
                                            control={form.control}
                                            name="state"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        State
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="State " {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField
                                            control={form.control}
                                            name="pinCode"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Pin Code
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="pincode" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField
                                            control={form.control}
                                            name="country"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Country
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Country " {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                        <FormField
                                            control={form.control}
                                            name="roles"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        Roles
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Roles " {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />

                                        {/* <FormField
                                            control={form.control}
                                            name="userType"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        user type
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                            <SelectTrigger className="">
                                                                <SelectValue placeholder="Select User Type" />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectItem value="admin">Admin</SelectItem>
                                                                    <SelectItem value="individual">Individual</SelectItem>
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} /> */}

                                        <FormField
                                            control={form.control}
                                            name="userCategory"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        user category
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                            <SelectTrigger className="">
                                                                <SelectValue placeholder="Select Category" />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectItem value="company">Company</SelectItem>
                                                                    <SelectItem value="freelancer">Freelancer</SelectItem>
                                                                    <SelectItem value="etc">Etc</SelectItem>
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )} />
                                    </Div>

                                    <Button type="submit" className="mt-2">Submit</Button>
                                </form>
                            </Form >
                        </CardDescription >
                    </CardContent >
                </Card >
            </Div >
        </Div >
    )
}

export default Register


// function SelectState({ field }: any) {
//     return (<Select {...field}>
//         <SelectTrigger >
//             <SelectValue placeholder="State" />
//         </SelectTrigger>
//         <SelectContent>
//             <SelectGroup>
//                 <SelectLabel>South America</SelectLabel>
//                 <SelectItem value="art">Argentina Time (ART)</SelectItem>
//                 <SelectItem value="bot">Bolivia Time (BOT)</SelectItem>
//                 <SelectItem value="brt">Brasilia Time (BRT)</SelectItem>
//                 <SelectItem value="clt">Chile Standard Time (CLT)</SelectItem>
//             </SelectGroup>
//         </SelectContent>
//     </Select>)
// }


// function SelectCountry({ field }: any) {
//     return (<Select {...field}>
//         <SelectTrigger >
//             <SelectValue placeholder="Country" />
//         </SelectTrigger>
//         <SelectContent>

//             <SelectGroup>
//                 <SelectLabel>South America</SelectLabel>
//                 <SelectItem value="art">Argentina Time (ART)</SelectItem>
//                 <SelectItem value="bot">Bolivia Time (BOT)</SelectItem>
//                 <SelectItem value="brt">Brasilia Time (BRT)</SelectItem>
//                 <SelectItem value="clt">Chile Standard Time (CLT)</SelectItem>
//             </SelectGroup>
//         </SelectContent>
//     </Select>)
// }