import { toast } from "sonner";
import { claimUpdateDto, RewardClaimDto } from "src/components/Types";
import { routes } from "src/lib/routes";
import { create } from "zustand";

interface ClaimStore {
  claims: RewardClaimDto[];
  totalProjectOverview: number;
  totalElements: number;
  page: number;
  totalPages: number;
  size: number;

  year: string | null;
  quarter: string | null;
  filters: {};

  resetAll: () => void;
  setQuarterYear: (quarter: string, year: string) => void;
  defaultMonthFilter: () => void;
  updatePageSize: (page?: number, size?: number) => void;
  getClaimProjects: (data: any) => Promise<void>;

  updateClaimStatus: (data: any) => Promise<void>;
}

const initialState ={ 
  claims: [],
  totalProjectOverview: 0,
  totalElements: 0,
  page: 0,
  totalPages: 0,
  size: 10,
  filters: {},
  year: null,
  quarter: null,
}

export const useClaimStore = create<ClaimStore>((set, get) => ({
  ...initialState,
  resetAll() {
    set({...initialState})
  },
  defaultMonthFilter() {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let q = month < 3 ? 1 : month < 6 ? 2 : month < 9 ? 3 : month < 12 ? 4 : 1;

    set({
      quarter: `Q${q}`,
      year: String(year),
    });
  },
  setQuarterYear(quarter, year) {
    set({ quarter: quarter, year: year });
  },
  updatePageSize(page, size) {
    if (!size) set({ page, size: get().size });
    else set({ page, size });
  },

  async getClaimProjects(data) {
    const res: any = await routes.APIS.ADMIN.GET_ALL_REWARD_CLAIMS(data);

    set({
      claims: res.rewardResponseList,
      totalProjectOverview: res.projectSummary,
      totalElements: res.totalElements,
      totalPages: res.totalPages,
    });
  },

  async updateClaimStatus(obj: claimUpdateDto) {
    let key = obj.rewardKey;
    delete obj.rewardKey;

    routes.APIS.REWARD_CLAIMS.CLAIM_UPDATE(obj)
      .then((res: any) => {
        let claims = get().claims.map((item) =>
          item.rewardKey === key ? { ...item, ...obj } : item
        );
        set({ claims: claims });
        toast.success("Reward Claim updated!!");
      })
      .catch((e) => {
        console.log(e)
        toast.error("Couldn't update claim");
      });
  },
}));