import { useEffect, useState } from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "src/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { useClaimStore } from "src/store/claimStore";
import { paginate } from "src/views/admin-projects/projectstable";
import RewardStatus from "src/views/claims/rewardStatus";

export function ProjectClaims(props: any) {
  const {
    totalPages,
    page,
    updatePageSize,
    year,
    quarter,
    getClaimProjects,
    filters,
    claims,
    size,
  } = useClaimStore();
  const [emailId, setEmailId] = useState('')

  const prop = {
    page,
    totalPages,
    updatePageSize,
  };

  useEffect(() => {
    if (!year) return;
    getClaimProjects({
      page,
      size,
      projectYear: year,
      arpStatus: "approved",
      quarter,
      emailId
    });
  }, [page, size, year, quarter,emailId]);
  
  function handleEmailSearch(e:any) { 
    setEmailId(e.target.value)
  }
  return (
    <div>
      <Table>
        <TableHeader className="bg-black">
          <TableHead className="pl-4  rounded-s-md text-white">
            Reward Key
          </TableHead>

          <TableHead className={"text-white"}>
          Email
          </TableHead>
          <TableHead className={"text-white"}>TotalAmount</TableHead>
          <TableHead className={"text-white"}>Claim Status</TableHead>
          <TableHead className={"text-white rounded-e-md pr-4"}>
            Created
          </TableHead>
        </TableHeader>
        <TableBody>
          {Boolean(claims?.length) &&
            claims.map((claim: any) => (
              <TableRow key={claim.rewardKey}>
                <TableCell className=" text-center">{claim.rewardKey}</TableCell>

                <TableCell>{claim.emailId}</TableCell>
                <TableCell>{claim.totalAmount}</TableCell>
                <TableCell>
                  <RewardStatus claim={claim} />
                </TableCell>
                <TableCell>
                  {claim?.createdDate &&
                    new Date(claim?.createdDate).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {Boolean(!claims?.length) && (
        <div className=" text-center p-6">Welp, No rewards found!!</div>
      )}
      <Paginate {...prop} />
    </div>
  );
}

function Paginate(props: any) {
  const { page, totalPages, updatePageSize } = props;
  const [pages, setPages] = useState<Array<string | number>>([]);

  useEffect(() => {
    if (totalPages === 0) return;
    setPages(paginate(page, totalPages));
  }, [page, totalPages]);

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem
          onClick={() => {
            updatePageSize(page - 1);
          }}
          hidden={page == 0}
        >
          <PaginationPrevious href="#" />
        </PaginationItem>
        {pages.length > 1 && pages.map((_: any, index: number) => (
          <PaginationItem
            onClick={() => {
              if (typeof _ === "number") {
                updatePageSize(_);
              }
            }}
          >
            <PaginationLink isActive={_ === page} href="#">
              {_ + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          hidden={page >= totalPages - 1}
          onClick={() => {
            updatePageSize(page + 1);
          }}
        >
          <PaginationNext href="#" />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
