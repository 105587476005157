import { ArrowLeftCircle, CheckCircle2Icon } from "lucide-react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RewardClaim } from "src/components/Types";
import { Icons } from "src/components/custom/icons";
import ColorCard from "src/components/typography/colorcard";
import Div from "src/components/typography/div";
import H3 from "src/components/typography/h3";
import Lead from "src/components/typography/lead";
import { Card } from "src/components/ui/card";
import { Label } from "src/components/ui/label";
import { Separator } from "src/components/ui/separator";
import Spinner from "src/components/ui/spinner";
import { useProjectStore } from "src/store/projectStore";

function RewardClaimRequestProgress({ project }: any) {
  return project.rewardStatus === 'created' ? (
    <ColorCard
      message="The Reward Claim has been created. It has been sent for Business approval"
      variant="warning"
    />
  ) : project.rewardStatus === 'businessApproved' ? (
    <ColorCard
      message="Business Approval has been successfully completed. It has been sent for Financial approval"
      variant="warning"
    />
  ) : project.rewardStatus === 'financeApproved' ? (
    <ColorCard
      message="Financial Approval has been successfully completed. The rewards should be transfered to your bank account in 3-4 Working Business days"
      variant="warning"
    />
  ) : project.rewardStatus === 'completed' ? (
    <ColorCard
      message="Congratulations! This Reward Claim has been completed. The Amount has been transfered to your accounts."
      variant="success"
    />
  ) : (
    <ColorCard
      message="Looks like your request has been rejected, If you find any conflicts in this please contact us at partners@cloudzenia.com"
      variant="error"
    />
  );
}

function ApprovedProgressTabs({ project }: { project: RewardClaim }) {
  let arpEligible, createdBy, cashUnblocked, done;

  switch (project.rewardStatus) {
    case 'financeApproved':
      createdBy = true;
      arpEligible = true;
      cashUnblocked = true;
      done = false;
      break;
    case 'businessApproved':
      createdBy = true;
      arpEligible = true;
      cashUnblocked = false;
      done = false;
      break;
    case 'created':
      createdBy = true;
      cashUnblocked = false;
      arpEligible = false;
      done = false;
      break;
    case  'completed':
      createdBy = true;
      cashUnblocked = true;
      arpEligible = true;
      done = true;
      break;
    default:
      createdBy = false;
      cashUnblocked = false;
      arpEligible = false;
      done = false;
  }
  return (
    <Div className="text-center bg-white space-y-4">
      <div className="flex  justify-between ">
        <div className="relative flex justify-center w-min">
          <CheckCircle2Icon
            className={`${createdBy ? "text-green-500" : ""}`}
          />
          <Label className="absolute -bottom-4 ">Created</Label>{" "}
        </div>

        <div className=" items-center my-auto w-full">
          <Separator
            className={`p-[2px] ${arpEligible ? "bg-green-400" : ""}`}
          />
        </div>

        <div className=" relative flex justify-center w-min">
          <CheckCircle2Icon
            className={`${arpEligible ? "text-green-500" : ""}`}
          />{" "}
          <Label className="absolute w-max -bottom-4 ">Business Approval</Label>{" "}
        </div>

        <div className=" w-full items-center my-auto ">
          <Separator
            className={`p-[2px] ${cashUnblocked ? "bg-green-400" : ""}`}
          />
        </div>

        <div className="relative flex justify-center  w-min">
          <CheckCircle2Icon
            className={`p-[2px] ${cashUnblocked ? "text-green-400" : ""}`}
          />
          <Label className="absolute w-max -bottom-4 ">Finance Approved</Label>{" "}
        </div>

        <div className=" w-full items-center my-auto ">
          <Separator className={`p-[2px] ${done ? "bg-green-400" : ""}`} />
        </div>

        <div className=" relative flex justify-center w-min">
          <CheckCircle2Icon
            className={`p-[2px] ${done ? "text-green-400" : ""}`}
          />{" "}
          <Label className="absolute w-max -bottom-4 ">Completed </Label>{" "}
        </div>
      </div>
    </Div>
  );
}

function RequestDetilsTab() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { rewardClaim, getRewardClaimByKey } = useProjectStore();
  useEffect(() => {
    getRewardClaimByKey(searchParams.get("requestId") ?? "");
  }, []);
  if (!rewardClaim) return <Spinner />;
  return (
    <Div className="animate-in slide-in-from-right-6">
      <ArrowLeftCircle onClick={() => navigate(-1)} />
      <Card className="p-6 mt-6">
        <H3 className="mt-0">{"CRP Reward Claim"}</H3>
        <Lead className="font-light text-md">
          <Label className="text-black">{"Request ID: "} </Label>
          {rewardClaim?.rewardKey}
        </Lead>

        <RewardClaimRequestProgress project={rewardClaim} />
        <div className="w-full text-center py-12 ">
          <p className="w-12 h-12  mx-auto">
            <Icons.Approved />
          </p>
          <p className="text-primary font-bold">
          ₹ {rewardClaim?.totalAmount.toLocaleString()}
          </p>
          <p>{"Approved Amount"}</p>
        </div>
        <p className="text-center text-xl"> Stage you have completed</p>
        <ApprovedProgressTabs project={rewardClaim} />
      </Card>
    </Div>
  );
}

export default RequestDetilsTab;
