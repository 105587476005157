import { DialogTrigger } from "@radix-ui/react-dialog";
import { ChevronDown, ListFilter } from "lucide-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { USERTYPE } from "src/components/Types";
import SearchComponent from "src/components/custom/SearchComponent";
import CustomDropdown from "src/components/custom/dropdown";
import { Icons } from "src/components/custom/icons";
import Div from "src/components/typography/div";
import H3 from "src/components/typography/h3";
import Lead from "src/components/typography/lead";
import Table from "src/components/typography/table";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
} from "src/components/ui/dialog";
import { Label } from "src/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import Spinner from "src/components/ui/spinner";
import {
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import useDevice from "src/lib/hooks/useDevice";
import { useAuth } from "src/lib/useAuth";
import { cn } from "src/lib/utils";
import { useProjectStore } from "src/store/projectStore";
import MyProjectsTable from "src/views/myprojects/MyProjectsTable";
import AddProjectDialog from "src/views/myprojects/NewProject";
import ProjectDetails from "../dashboard/project/ProjectDetails";

export interface QuarterSelectionProps {
  quarters: { value: string }[];
}

export function SelectOptions({ quarters }: any) {
  return (
    <>
      {quarters.map((item: any) => (
        <SelectItem key={item.value} value={item.value}>
          <Label>{item.label}</Label>
        </SelectItem>
      ))}
    </>
  );
}

export function CustomCard({ className, title, stat, icon }: any) {
  return (
    <Card
      className={cn("p-6 max-h-32 shadow-none border-none w-full", className)}
      key={title}
    >
      <div className="flex gap-4">
        <div className="p-2 border rounded-full   w-16 h-16 ">
          {icon ?? "icon*"}
        </div>
        <div>
          <div>
            <Lead className="text-black text-[18px]">{title}</Lead>
          </div>
          <div>
            <H3 className="p-0 m-0">{stat}</H3>{" "}
          </div>
        </div>
      </div>
    </Card>
  );
}

function QuarterStatsCards({ quarterStats }: any) {
  return (
    <>
      <CustomCard
        title={"Total Projects"}
        stat={quarterStats?.totalProjectCount ?? 0}
        icon={<Icons.Projects className="w-full h-full" />}
      />
      <CustomCard
        title={"In ARP Review"}
        stat={quarterStats?.arpReviewProjectCount ?? 0}
        icon={<Icons.ARP className="w-full h-full" />}
      />
      <CustomCard
        title={"Approved"}
        stat={quarterStats?.approvedProjectCount ?? 0}
        icon={<Icons.Approved className="w-full h-full" />}
      />
      <CustomCard
        title={"Rejected"}
        stat={quarterStats?.rejectedProjectCount ?? 0}
        icon={<Icons.Rejected className="w-full h-full" />}
      />
    </>
  );
}

function ProjectFilter({ device }: any) {
  const { filterProjects } = useProjectStore();
  return (
    <Select onValueChange={(value) => filterProjects(value)}>
      <SelectTrigger className="w-min space-x-2 rounded-full focus:ring-0">
        <ListFilter />
        {device !== "mobile" && (
          <>
            <SelectValue placeholder="Filter" />
            <ChevronDown />
          </>
        )}
      </SelectTrigger>
      <SelectContent defaultValue={"all"} onChange={(e) => console.log(e)}>
        <SelectGroup>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="approved">Approved</SelectItem>
          <SelectItem value="rejected">Rejected</SelectItem>
          <SelectItem value="review">Review</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function RewardCriteriaDialog({ device }: any) {
  const rewards = [
    {
      Level: 1,
      PriceRange: "From $50.00 to $150.00",
      Points: "8,000",
    },
    {
      Level: 2,
      PriceRange: "From $150.00 to $349.00",
      Points: "25,000",
    },
    {
      Level: 3,
      PriceRange: "From $351.00 to $410.00",
      Points: "35,000",
    },
    {
      Level: 4,
      PriceRange: "From $420.00 to $450.00",
      Points: "50,000",
    },
    {
      Level: 5,
      PriceRange: "From $451.00 to $10,000.00",
      Points: "55,000",
    },
    {
      Level: 6,
      PriceRange: "From $10,000.00 to $20,000.00",
      Points: "65,000",
    },
    {
      Level: 7,
      link: true,
      PriceRange: "From $20,001.00 to $100,000.00",
      Points: "Contact us",
    },
  ];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant={device === "mobile" ? "default" : "outline"}
          className={`${
            device === "mobile" ? "mt-3" : ""
          }   w-max  rounded-full  px-4 text-md `}
        >
          Reward Criteria
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogDescription>
          <Table>
            <TableHeader className=" text-lg w-full">
              <span className="">Reward Crtieria</span>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>Levels</TableCell>
                <TableCell>MRR Range</TableCell>

                <TableCell>Reward in INR</TableCell>
              </TableRow>
              {rewards.map((Item: any) => (
                <TableRow key={Item.Level}>
                  <TableCell>Level {Item.Level}</TableCell>
                  <TableCell>{Item.PriceRange}</TableCell>

                  {Item.link ? (
                    <TableCell>
                      {" "}
                      <a
                        className="hover:underline text-primary"
                        href="mailto:
partners@cloudzenia.com"
                      >
                        {" "}
                        {Item.Points}
                      </a>
                    </TableCell>
                  ) : (
                    <TableCell>{Item.Points}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

// function BulkUploadSheet() {
//     const [checkedBulkUplaod, setCheckedBulkUplaod] = useState(false)
//     const templatelink = ""

//     return (
//         <Sheet>
//             <SheetTrigger asChild>
//                 <Button disabled variant="outline">...</Button>
//             </SheetTrigger>
//             <SheetContent className="rounded-2xl m-2 max-w-2xl">
//                 <SheetHeader >
//                     <SheetTitle className="mx-auto" >Bulk Upload</SheetTitle>
//                 </SheetHeader>

//                 <div className="">

//                     {checkedBulkUplaod && <ColorCard variant="error" className="  animate-in duration-300 slide-in-from-top-4 " message="Download the template from the link below, fill it with all the project details and check ready to upload ">
//                         <Link to={templatelink} className="flex text-orange-400 hover:underline  hover:underline-offset-2 py-2 space-x-2"> <DownloadIcon className="text-orange-400" /> <p className="text-orange-400 text-md">Download the temlpate</p></Link></ColorCard>
//                     }

//                     <Div className="flex gap-4"> <Checkbox id="terms" className="w-5 h-5" onCheckedChange={() => setCheckedBulkUplaod(!checkedBulkUplaod)} />
//                         <label
//                             htmlFor="terms"

//                             className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                         >
//                             <div className="space-y-2">Ready to upload?
//                                 <Lead className="text-xs">I have updated the template with all my prohects.</Lead></div>
//                         </label>
//                     </Div>

//                     {!checkedBulkUplaod && <>
//                         <ColorCard variant="error" message="Please ensure you meet the required format before you upload the file."></ColorCard>

//                         <Div className="flex gap-4"> <Checkbox id="terms" className="w-5 h-5" onCheckedChange={() => setCheckedBulkUplaod(!checkedBulkUplaod)} />
//                             <label
//                                 htmlFor="terms"

//                                 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                             >
//                                 <div className="space-y-2">Column Format
//                                     <Lead className="text-xs">I have not added or moved any columns in the template</Lead></div>
//                             </label>
//                         </Div>

//                         <Div className="flex gap-4"> <Checkbox id="terms" className="w-5 h-5" onCheckedChange={() => setCheckedBulkUplaod(!checkedBulkUplaod)} />
//                             <label
//                                 htmlFor="terms"

//                                 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                             >
//                                 <div className="space-y-2">First Row
//                                     <Lead className="text-xs">I have not moved or edited the first row i.e the Header row</Lead></div>
//                             </label>
//                         </Div>

//                         <Div className="flex gap-4"> <Checkbox id="terms" className="w-5 h-5" onCheckedChange={() => setCheckedBulkUplaod(!checkedBulkUplaod)} />
//                             <label
//                                 htmlFor="terms"

//                                 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                             >
//                                 <div className="space-y-2">Row Format
//                                     <Lead className="text-xs">I have added all my projects in rows with no missing information and no blank row in between the data</Lead></div>
//                             </label>
//                         </Div>

//                         <Div className="flex gap-4"> <Checkbox id="terms" className="w-5 h-5" onCheckedChange={() => setCheckedBulkUplaod(!checkedBulkUplaod)} />
//                             <label
//                                 htmlFor="terms"

//                                 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                             >
//                                 <div className="space-y-2">No blank rows
//                                     <Lead className="text-xs">I have deleted all the blank rows after the last project row in the file/</Lead></div>
//                             </label>
//                         </Div>
//                     </>
//                     }
//                 </div>
//                 <SheetDescription></SheetDescription>
//             </SheetContent>
//         </Sheet >
//     )
// }

function SearchProjects(props: any) {
  return (
    <Div className="md:flex gap-4 pb-0  rounded-t-xl bg-white">
      <div className="flex gap-4">
        <SearchComponent />
        <ProjectFilter device={props.device} />
      </div>
      {props.device !== "mobile" && (
        <AddProjectDialog title={"Add A Project"} />
      )}

      <RewardCriteriaDialog device={props.device} />
      {/* <BulkUploadSheet /> */}
    </Div>
  );
}

export function SelectQuarter({ quarter, year, setQuarterYear }: any) {

  const quarters = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ];
  const years = [
    { value: "2024", label: 2024 },
    { value: "2025", label: 2025 },
  ];
  if (!quarter || !year) return <div> Something bad </div>;
  return (
    <div className="flex ">
      <Select
        defaultValue={quarter ?? "Q1"}
        onValueChange={(e) => setQuarterYear(e, year)}
      >
        <SelectTrigger className=" focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue placeholder="Quarter" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={quarters} />
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        defaultValue={"2024"}
        onValueChange={(e) => setQuarterYear(quarter, e)}
      >
        <SelectTrigger className=" focus:ring-0 focus-visible:ring-offset- text-sm border-none shadow-none min-w-max">
          <CustomDropdown className="w-full pl-4">
            <SelectValue placeholder="Year" />
          </CustomDropdown>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectOptions quarters={years} />
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function MyProjects() {
  const stats = {
    totalProjectCount: 0,
    arpReviewProjectCount: 0,
    approvedProjectCount: 0,
    rejectedProjectCount: 0,
  };

  const device = useDevice();

  const {
    projects,
    filteredProjects,
    setQuarterYear,
    year,
    quarter,
    totalProjectOverview,
  } = useProjectStore();


  if (!projects) return <Spinner />;
  return (
    <div>
      <Div className="flex  pb-2 items-center justify-between">
        <h3 className="text-xl">Overview</h3>
        <SelectQuarter
          setQuarterYear={setQuarterYear}
          year={year}
          quarter={quarter}
        />
      </Div>
      <Div
        className="grid grid-cols-1
                     sm:grid-cols-2 md:grid-cols-2
                     lg:grid-cols-4  gap-6 py-0"
      >
        <QuarterStatsCards quarterStats={totalProjectOverview ?? stats} />
      </Div>
      <Div>
        <span className="md:flex bg-white rounded-xl justify-between">
          <SearchProjects device={device} />
        </span>
        <MyProjectsTable projects={filteredProjects ?? projects} />
      </Div>
    </div>
  );
}
function MyProjectsTab() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const isAdmin = user?.userType === USERTYPE.SUPER_ADMIN;

  const { getProjectsWithFilters, quarter, year,monthFilter } = useProjectStore();

  useEffect(() => {
    if (quarter === null) {
      monthFilter();
    }
  }, [useProjectStore().quarter]);

  useEffect(() => {
    if (!user) return;
    if (quarter && year)
      getProjectsWithFilters({
        emailId: user.emailId,
        projectYear: year,
        quarter: quarter,
      });
  }, [quarter, year]);
  return (
    <>
      <div>
        {searchParams.get("projectKey") ? <ProjectDetails /> : <MyProjects />}
      </div>
    </>
  );
}

export default MyProjectsTab;
