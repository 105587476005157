import { cn } from "src/lib/utils";
import { CustomTypographyProps } from "../Types";

function H3({ children, className }: CustomTypographyProps) {
    return (
        <h3 className={cn("mt-8 scroll-m-20 text-2xl font-semibold tracking-tight", className)} >{children}</h3>

    )
}

export default H3