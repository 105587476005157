export const CountryList = [
    {
        "shortName": "Afghanistan",
        "fullName": "Islamic Republic of Afghanistan"
    },
    {
        "shortName": "Albania",
        "fullName": "Republic of Albania"
    },
    {
        "shortName": "Algeria",
        "fullName": "People's Democratic Republic of Algeria"
    },
    {
        "shortName": "Andorra",
        "fullName": "Principality of Andorra"
    },
    {
        "shortName": "Angola",
        "fullName": "Republic of Angola"
    },
    {
        "shortName": "Antigua and Barbuda",
        "fullName": "Antigua and Barbuda"
    },
    {
        "shortName": "Argentina",
        "fullName": "Argentine Republic"
    },
    {
        "shortName": "Armenia",
        "fullName": "Republic of Armenia"
    },
    {
        "shortName": "Australia",
        "fullName": "Commonwealth of Australia"
    },
    {
        "shortName": "Austria",
        "fullName": "Republic of Austria"
    },
    {
        "shortName": "Azerbaijan",
        "fullName": "Republic of Azerbaijan"
    },
    {
        "shortName": "Bahamas",
        "fullName": "Commonwealth of The Bahamas"
    },
    {
        "shortName": "Bahrain",
        "fullName": "Kingdom of Bahrain"
    },
    {
        "shortName": "Bangladesh",
        "fullName": "People's Republic of Bangladesh"
    },
    {
        "shortName": "Barbados",
        "fullName": "Barbados"
    },
    {
        "shortName": "Belarus",
        "fullName": "Republic of Belarus"
    },
    {
        "shortName": "Belgium",
        "fullName": "Kingdom of Belgium"
    },
    {
        "shortName": "Belize",
        "fullName": "Belize"
    },
    {
        "shortName": "Benin",
        "fullName": "Republic of Benin"
    },
    {
        "shortName": "Bhutan",
        "fullName": "Kingdom of Bhutan"
    },
    {
        "shortName": "Bolivia",
        "fullName": "Plurinational State of Bolivia"
    },
    {
        "shortName": "Bosnia and Herzegovina",
        "fullName": "Bosnia and Herzegovina"
    },
    {
        "shortName": "Botswana",
        "fullName": "Republic of Botswana"
    },
    {
        "shortName": "Brazil",
        "fullName": "Federative Republic of Brazil"
    },
    {
        "shortName": "Brunei",
        "fullName": "Nation of Brunei, the Abode of Peace"
    },
    {
        "shortName": "Bulgaria",
        "fullName": "Republic of Bulgaria"
    },
    {
        "shortName": "Burkina Faso",
        "fullName": "Burkina Faso"
    },
    {
        "shortName": "Burundi",
        "fullName": "Republic of Burundi"
    },
    {
        "shortName": "Cabo Verde",
        "fullName": "Republic of Cabo Verde"
    },
    {
        "shortName": "Cambodia",
        "fullName": "Kingdom of Cambodia"
    },
    {
        "shortName": "Cameroon",
        "fullName": "Republic of Cameroon"
    },
    {
        "shortName": "Canada",
        "fullName": "Canada"
    },
    {
        "shortName": "Central African Republic",
        "fullName": "Central African Republic"
    },
    {
        "shortName": "Chad",
        "fullName": "Republic of Chad"
    },
    {
        "shortName": "Chile",
        "fullName": "Republic of Chile"
    },
    {
        "shortName": "China",
        "fullName": "People's Republic of China"
    },
    {
        "shortName": "Colombia",
        "fullName": "Republic of Colombia"
    },
    {
        "shortName": "Comoros",
        "fullName": "Union of the Comoros"
    },
    {
        "shortName": "Congo (Congo-Brazzaville)",
        "fullName": "Republic of the Congo"
    },
    {
        "shortName": "Costa Rica",
        "fullName": "Republic of Costa Rica"
    },
    {
        "shortName": "Croatia",
        "fullName": "Republic of Croatia"
    },
    {
        "shortName": "Cuba",
        "fullName": "Republic of Cuba"
    },
    {
        "shortName": "Cyprus",
        "fullName": "Republic of Cyprus"
    },
    {
        "shortName": "Czechia (Czech Republic)",
        "fullName": "Czech Republic"
    },
    {
        "shortName": "Denmark",
        "fullName": "Kingdom of Denmark"
    },
    {
        "shortName": "Djibouti",
        "fullName": "Republic of Djibouti"
    },
    {
        "shortName": "Dominica",
        "fullName": "Commonwealth of Dominica"
    },
    {
        "shortName": "Dominican Republic",
        "fullName": "Dominican Republic"
    },
    {
        "shortName": "Ecuador",
        "fullName": "Republic of Ecuador"
    },
    {
        "shortName": "Egypt",
        "fullName": "Arab Republic of Egypt"
    },
    {
        "shortName": "El Salvador",
        "fullName": "Republic of El Salvador"
    },
    {
        "shortName": "Equatorial Guinea",
        "fullName": "Republic of Equatorial Guinea"
    },
    {
        "shortName": "Eritrea",
        "fullName": "State of Eritrea"
    },
    {
        "shortName": "Estonia",
        "fullName": "Republic of Estonia"
    },
    {
        "shortName": "Eswatini (fmr. Swaziland)",
        "fullName": "Kingdom of Eswatini"
    },
    {
        "shortName": "Ethiopia",
        "fullName": "Federal Democratic Republic of Ethiopia"
    },
    {
        "shortName": "Fiji",
        "fullName": "Republic of Fiji"
    },
    {
        "shortName": "Finland",
        "fullName": "Republic of Finland"
    },
    {
        "shortName": "France",
        "fullName": "French Republic"
    },
    {
        "shortName": "Gabon",
        "fullName": "Gabonese Republic"
    },
    {
        "shortName": "Gambia",
        "fullName": "Republic of The Gambia"
    },
    {
        "shortName": "Georgia",
        "fullName": "Georgia"
    },
    {
        "shortName": "Germany",
        "fullName": "Federal Republic of Germany"
    },
    {
        "shortName": "Ghana",
        "fullName": "Republic of Ghana"
    },
    {
        "shortName": "Greece",
        "fullName": "Hellenic Republic"
    },
    {
        "shortName": "Grenada",
        "fullName": "Grenada"
    },
    {
        "shortName": "Guatemala",
        "fullName": "Republic of Guatemala"
    },
    {
        "shortName": "Guinea",
        "fullName": "Republic of Guinea"
    },
    {
        "shortName": "Guinea-Bissau",
        "fullName": "Republic of Guinea-Bissau"
    },
    {
        "shortName": "Guyana",
        "fullName": "Co-operative Republic of Guyana"
    },
    {
        "shortName": "Haiti",
        "fullName": "Republic of Haiti"
    },
    {
        "shortName": "Holy See",
        "fullName": "Vatican City State"
    },
    {
        "shortName": "Honduras",
        "fullName": "Republic of Honduras"
    },
    {
        "shortName": "Hungary",
        "fullName": "Hungary"
    },
    {
        "shortName": "Iceland",
        "fullName": "Republic of Iceland"
    },
    {
        "shortName": "India",
        "fullName": "Republic of India"
    },
    {
        "shortName": "Indonesia",
        "fullName": "Republic of Indonesia"
    },
    {
        "shortName": "Iran",
        "fullName": "Islamic Republic of Iran"
    },
    {
        "shortName": "Iraq",
        "fullName": "Republic of Iraq"
    },
    {
        "shortName": "Ireland",
        "fullName": "Republic of Ireland"
    },
    {
        "shortName": "Israel",
        "fullName": "State of Israel"
    },
    {
        "shortName": "Italy",
        "fullName": "Italian Republic"
    },
    {
        "shortName": "Jamaica",
        "fullName": "Jamaica"
    },
    {
        "shortName": "Japan",
        "fullName": "Japan"
    },
    {
        "shortName": "Jordan",
        "fullName": "Hashemite Kingdom of Jordan"
    },
    {
        "shortName": "Kazakhstan",
        "fullName": "Republic of Kazakhstan"
    },
    {
        "shortName": "Kenya",
        "fullName": "Republic of Kenya"
    },
    {
        "shortName": "Kiribati",
        "fullName": "Republic of Kiribati"
    },
    {
        "shortName": "Korea, Democratic People's Republic of (North Korea)",
        "fullName": "Democratic People's Republic of Korea"
    },
    {
        "shortName": "Korea, Republic of (South Korea)",
        "fullName": "Republic of Korea"
    },
    {
        "shortName": "Kosovo",
        "fullName": "Republic of Kosovo"
    },
    {
        "shortName": "Kuwait",
        "fullName": "State of Kuwait"
    },
    {
        "shortName": "Kyrgyzstan",
        "fullName": "Kyrgyz Republic"
    },
    {
        "shortName": "Laos",
        "fullName": "Lao People's Democratic Republic"
    },
    {
        "shortName": "Latvia",
        "fullName": "Republic of Latvia"
    },
    {
        "shortName": "Lebanon",
        "fullName": "Lebanese Republic"
    },
    {
        "shortName": "Lesotho",
        "fullName": "Kingdom of Lesotho"
    },
    {
        "shortName": "Liberia",
        "fullName": "Republic of Liberia"
    },
    {
        "shortName": "Libya",
        "fullName": "State of Libya"
    },
    {
        "shortName": "Liechtenstein",
        "fullName": "Principality of Liechtenstein"
    },
    {
        "shortName": "Lithuania",
        "fullName": "Republic of Lithuania"
    },
    {
        "shortName": "Luxembourg",
        "fullName": "Grand Duchy of Luxembourg"
    },
    {
        "shortName": "Madagascar",
        "fullName": "Republic of Madagascar"
    },
    {
        "shortName": "Malawi",
        "fullName": "Republic of Malawi"
    },
    {
        "shortName": "Malaysia",
        "fullName": "Malaysia"
    },
    {
        "shortName": "Maldives",
        "fullName": "Republic of Maldives"
    },
    {
        "shortName": "Mali",
        "fullName": "Republic of Mali"
    },
    {
        "shortName": "Malta",
        "fullName": "Republic of Malta"
    },
    {
        "shortName": "Marshall Islands",
        "fullName": "Republic of the Marshall Islands"
    },
    {
        "shortName": "Mauritania",
        "fullName": "Islamic Republic of Mauritania"
    },
    {
        "shortName": "Mauritius",
        "fullName": "Republic of Mauritius"
    },
    {
        "shortName": "Mexico",
        "fullName": "United Mexican States"
    },
    {
        "shortName": "Micronesia",
        "fullName": "Federated States of Micronesia"
    },
    {
        "shortName": "Moldova",
        "fullName": "Republic of Moldova"
    },
    {
        "shortName": "Monaco",
        "fullName": "Principality of Monaco"
    },
    {
        "shortName": "Mongolia",
        "fullName": "Mongolia"
    },
    {
        "shortName": "Montenegro",
        "fullName": "Montenegro"
    },
    {
        "shortName": "Morocco",
        "fullName": "Kingdom of Morocco"
    },
    {
        "shortName": "Mozambique",
        "fullName": "Republic of Mozambique"
    },
    {
        "shortName": "Myanmar",
        "fullName": "Republic of the Union of Myanmar"
    },
    {
        "shortName": "Namibia",
        "fullName": "Republic of Namibia"
    },
    {
        "shortName": "Nauru",
        "fullName": "Republic of Nauru"
    },
    {
        "shortName": "Nepal",
        "fullName": "Federal Democratic Republic of Nepal"
    },
    {
        "shortName": "Netherlands",
        "fullName": "Kingdom of the Netherlands"
    },
    {
        "shortName": "New Zealand",
        "fullName": "New Zealand"
    },
    {
        "shortName": "Nicaragua",
        "fullName": "Republic of Nicaragua"
    },
    {
        "shortName": "Niger",
        "fullName": "Republic of the Niger"
    },
    {
        "shortName": "Nigeria",
        "fullName": "Federal Republic of Nigeria"
    },
    {
        "shortName": "North Macedonia",
        "fullName": "Republic of North Macedonia"
    },
    {
        "shortName": "Norway",
        "fullName": "Kingdom of Norway"
    },
    {
        "shortName": "Oman",
        "fullName": "Sultanate of Oman"
    },
    {
        "shortName": "Pakistan",
        "fullName": "Islamic Republic of Pakistan"
    },
    {
        "shortName": "Palau",
        "fullName": "Republic of Palau"
    },
    {
        "shortName": "Palestine State",
        "fullName": "State of Palestine"
    },
    {
        "shortName": "Panama",
        "fullName": "Republic of Panama"
    },
    {
        "shortName": "Papua New Guinea",
        "fullName": "Independent State of Papua New Guinea"
    },
    {
        "shortName": "Paraguay",
        "fullName": "Republic of Paraguay"
    },
    {
        "shortName": "Peru",
        "fullName": "Republic of Peru"
    },
    {
        "shortName": "Philippines",
        "fullName": "Republic of the Philippines"
    },
    {
        "shortName": "Poland",
        "fullName": "Republic of Poland"
    },
    {
        "shortName": "Portugal",
        "fullName": "Portuguese Republic"
    },
    {
        "shortName": "Qatar",
        "fullName": "State of Qatar"
    },
    {
        "shortName": "Romania",
        "fullName": "Romania"
    },
    {
        "shortName": "Russia",
        "fullName": "Russian Federation"
    },
    {
        "shortName": "Rwanda",
        "fullName": "Republic of Rwanda"
    },
    {
        "shortName": "Saint Kitts and Nevis",
        "fullName": "Federation of Saint Kitts and Nevis"
    },
    {
        "shortName": "Saint Lucia",
        "fullName": "Saint Lucia"
    },
    {
        "shortName": "Saint Vincent and the Grenadines",
        "fullName": "Saint Vincent and the Grenadines"
    },
    {
        "shortName": "Samoa",
        "fullName": "Independent State of Samoa"
    },
    {
        "shortName": "San Marino",
        "fullName": "Republic of San Marino"
    },
    {
        "shortName": "Sao Tome and Principe",
        "fullName": "Democratic Republic of Sao Tome and Principe"
    },
    {
        "shortName": "Saudi Arabia",
        "fullName": "Kingdom of Saudi Arabia"
    },
    {
        "shortName": "Senegal",
        "fullName": "Republic of Senegal"
    },
    {
        "shortName": "Serbia",
        "fullName": "Republic of Serbia"
    },
    {
        "shortName": "Seychelles",
        "fullName": "Republic of Seychelles"
    },
    {
        "shortName": "Sierra Leone",
        "fullName": "Republic of Sierra Leone"
    },
    {
        "shortName": "Singapore",
        "fullName": "Republic of Singapore"
    },
    {
        "shortName": "Slovakia",
        "fullName": "Slovak Republic"
    },
    {
        "shortName": "Slovenia",
        "fullName": "Republic of Slovenia"
    },
    {
        "shortName": "Solomon Islands",
        "fullName": "Solomon Islands"
    },
    {
        "shortName": "Somalia",
        "fullName": "Federal Republic of Somalia"
    },
    {
        "shortName": "South Africa",
        "fullName": "Republic of South Africa"
    },
    {
        "shortName": "South Sudan",
        "fullName": "Republic of South Sudan"
    },
    {
        "shortName": "Spain",
        "fullName": "Kingdom of Spain"
    },
    {
        "shortName": "Sri Lanka",
        "fullName": "Democratic Socialist Republic of Sri Lanka"
    },
    {
        "shortName": "Sudan",
        "fullName": "Republic of the Sudan"
    },
    {
        "shortName": "Suriname",
        "fullName": "Republic of Suriname"
    },
    {
        "shortName": "Sweden",
        "fullName": "Kingdom of Sweden"
    },
    {
        "shortName": "Switzerland",
        "fullName": "Swiss Confederation"
    },
    {
        "shortName": "Syria",
        "fullName": "Syrian Arab Republic"
    },
    {
        "shortName": "Taiwan",
        "fullName": "Taiwan, Province of China"
    },
    {
        "shortName": "Tajikistan",
        "fullName": "Republic of Tajikistan"
    },
    {
        "shortName": "Tanzania",
        "fullName": "United Republic of Tanzania"
    },
    {
        "shortName": "Thailand",
        "fullName": "Kingdom of Thailand"
    },
    {
        "shortName": "Timor-Leste",
        "fullName": "Democratic Republic of Timor-Leste"
    },
    {
        "shortName": "Togo",
        "fullName": "Togolese Republic"
    },
    {
        "shortName": "Tonga",
        "fullName": "Kingdom of Tonga"
    },
    {
        "shortName": "Trinidad and Tobago",
        "fullName": "Republic of Trinidad and Tobago"
    },
    {
        "shortName": "Tunisia",
        "fullName": "Tunisian Republic"
    },
    {
        "shortName": "Turkey",
        "fullName": "Republic of Turkey"
    },
    {
        "shortName": "Turkmenistan",
        "fullName": "Turkmenistan"
    },
    {
        "shortName": "Tuvalu",
        "fullName": "Tuvalu"
    },
    {
        "shortName": "Uganda",
        "fullName": "Republic of Uganda"
    },
    {
        "shortName": "Ukraine",
        "fullName": "Ukraine"
    },
    {
        "shortName": "United Arab Emirates",
        "fullName": "United Arab Emirates"
    },
    {
        "shortName": "United Kingdom",
        "fullName": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
        "shortName": "United States of America",
        "fullName": "United States of America"
    },
    {
        "shortName": "Uruguay",
        "fullName": "Eastern Republic of Uruguay"
    },
    {
        "shortName": "Uzbekistan",
        "fullName": "Republic of Uzbekistan"
    },
    {
        "shortName": "Vanuatu",
        "fullName": "Republic of Vanuatu"
    },
    {
        "shortName": "Venezuela",
        "fullName": "Bolivarian Republic of Venezuela"
    },
    {
        "shortName": "Vietnam",
        "fullName": "Socialist Republic of Vietnam"
    },
    {
        "shortName": "Yemen",
        "fullName": "Republic of Yemen"
    },
    {
        "shortName": "Zambia",
        "fullName": "Republic of Zambia"
    },
    {
        "shortName": "Zimbabwe",
        "fullName": "Republic of Zimbabwe"
    }
]