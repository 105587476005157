import { ChevronDown } from 'lucide-react'
import React, { ReactNode } from 'react'
import { cn } from 'src/lib/utils'
interface CustomDropDownProps {
    children: ReactNode
    className?: string
}
function CustomDropdown({ children, className }: CustomDropDownProps) {
    return (
        <div className={cn('border  flex justify-between items-center rounded-full p-1', className)}>
            {children}
            <ChevronDown className=' ' />
        </div >
    )
}

export default CustomDropdown