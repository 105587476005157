
import React from 'react'
import { useAuth } from 'src/lib/useAuth'
import CritieriaPage, { FAQ, HowItWorks } from 'src/views/home/CritieriaPage'
import LandingFooter from 'src/views/home/Footer'
import HeroPage, { Collab, ProgramFor } from 'src/views/home/HeroPage'
import Header from 'src/views/home/header'

function Home() {

    return (
        <div>
            <Header />
            <HeroPage />
            <ProgramFor />
            <Collab />
            <CritieriaPage />
            <HowItWorks />
            <FAQ />
            <LandingFooter />
        </div>
    )
}

export default Home 