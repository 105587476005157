
import H4 from 'src/components/typography/h4'
import { Card, CardDescription, CardFooter, CardTitle } from 'src/components/ui/card'
import { useAuth } from 'src/lib/useAuth'

function UserInvitation() {
    const {user} = useAuth()
    return (
        <Card className='flex-1 m-6'>
            <div className=' w-full h-full  flex flex-col  justify-center items-center'>
                <img className='mx-auto max-w-[400px]  py-12' src="/images/ohno.svg" alt="" />
                <CardTitle className='text-3xl font-normal text-center'>{"We're here to help."}</CardTitle>
                <CardDescription className='text-center  text-md max-w-3xl mx-auto py-2 px-12'>

                    <div>Hello, {user?.emailId} Looks like <br />you are not invited yet
                    </div>
                </CardDescription>
                < CardFooter className='pt-6 flex flex-col'>
                    <H4 className='text-center mx-auto font-normal' >Email us to get invited</H4>
                    <a className='text-primary' href="mailto:partners@cloudzenia.com">partners@cloudzenia.com</a>
                </CardFooter>
            </div>            </Card>

    )
}

export default UserInvitation